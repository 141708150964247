import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from '../../../app/hooks';
import { Link, useParams, useHistory } from 'react-router-dom';
import { ApiObject, PaymentPlan, PaymentTransaction, Statement } from '../../../app/type';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import copyToClipboard from 'copy-text-to-clipboard';

import { sellerApi } from '../../../common/api';
import { SellerState, setPaymentPlan } from '../sellerSlice';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBagShopping,
  faCreditCard,
  faEye,
  faMoneyBill1,
  faRotateRight,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare, faTrashCan, faCopy } from '@fortawesome/free-regular-svg-icons';

import styles from './Index.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import {
  formatCurrency,
  formatNumber,
  plainNumberFormatter,
  decimalNumberFormatter,
  formatDecimalNumber,
  setDocumentTitle,
} from '../../../common/utils';
import { CURRENCIES } from '../../../common/constants/currencies';

import Stepper from '../../../common/components/Stepper';
import InfoBox from '../../../common/components/InfoBox';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';
import { Button, Drawer } from '@mui/material';
import { FRONT_URL } from '../../../common/api/client';
import Avatar from '../../../common/components/Avatar';
import TableList from '../../../common/components/TableList';

const Show = (): React.ReactElement => {
  const { paymentPlan, company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  setDocumentTitle(paymentPlan ? paymentPlan.product.name : 'Planes de Pago');
  const { paymentPlanId } = useParams<{ paymentPlanId: string }>();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [loading, setLoading] = useState<boolean>(false);
  const statementsRef = useRef<{ reloadResource: () => void }>();
  const dispatch = useDispatch();
  const [infoBoxesData, setInfoBoxes] = useState<{ [key: string]: number }>({});
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setOpenDialog] = useState<boolean>(false);
  const [dialogContext, setDialogContext] = useState<string>();
  const [statement, setStatement] = useState<Statement>();
  const [openSendEmail, setOpenSendEmail] = useState<boolean>(false);
  const [openRefundPopup, setOpenRefundPopup] = useState<boolean>(false);
  const [selectedStatement, setSelectedStatement] = useState<Statement>();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [paymentTransactions, setPaymentTransactions] = useState<PaymentTransaction[]>([]);
  const [openRetryChargePopup, setOpenRetryChargePopup] = useState<boolean>(false);
  const portalLink = `${FRONT_URL}/client/login?company_id=${company?.id}`;

  useEffect(() => {
    if (paymentPlanId) {
      setLoading(true);
      sellerApi.paymentPlans
        .show(paymentPlanId)
        .then((data: ApiObject<PaymentPlan>) => {
          dispatch(setPaymentPlan(data.data));
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error al obtener el servicio', { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
      sellerApi.paymentPlans
        .showInfoBoxes(paymentPlanId)
        .then((data) => setInfoBoxes(data.data))
        .catch((error) => console.error(error));
    }
  }, [paymentPlanId]);

  const sendClientPortalEmail = (companyId: string, buyerId: string) => {
    sellerApi.companies
      .sendClientPortalEmail(companyId, buyerId)
      .then(() => {
        enqueueSnackbar('Se enviará el correo', { variant: 'info' });
      })
      .catch(() => {
        enqueueSnackbar('Correo no pudo ser enviado', { variant: 'error' });
      });
  };

  const onLinkCopy = () => {
    copyToClipboard(portalLink);
    enqueueSnackbar('Enlace copiado', { variant: 'info' });
  };

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="TOTAL RECAUDADO"
      data={formatCurrency(infoBoxesData.total_gathered || 0)}
      kind="info2"
      style={styles.firstInfoContainer}
      icon={<FontAwesomeIcon icon={faBagShopping} className="icon" />}
      width={width}
    />,
    <InfoBox
      key={2}
      title="TOTAL DEUDA"
      data={formatCurrency(infoBoxesData.total_debt || 0)}
      kind="info2"
      style={styles.secondInfoContainer}
      icon={<FontAwesomeIcon icon={faCreditCard} className="icon" />}
      width={width}
    />,
    <InfoBox
      key={3}
      title="Agregar cuota"
      function={() => {
        setOpenDialog(true);
        setDialogContext('create');
      }}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/girl%20and%20boy%20working%20together%20in%20front%20of%20laptop.svg"
      style={styles.linkContainer}
      width={width}
    />,
  ];

  const statementStatusAction = (statement: Statement) => {
    const hash: { [key: string]: any } = {
      expired: [],
      paid: [{ label: 'Devolver', action: 'refund', icon: faMoneyBill1 }],
      pending: [],
    };

    if (statement.payable_card)
      hash['expired'] = [
        { label: 'Ver intentos', action: 'detail', icon: faEye },
        { label: 'Reintentar cobro', action: 'retry_charge', icon: faRotateRight },
      ];
    if (!company?.hide_payment_plan_edition)
      hash['pending'] = [
        { label: 'Editar cuota', action: 'edit', icon: faPenToSquare },
        { label: 'Eliminar cuota', action: 'delete', icon: faTrashCan },
      ];
    return hash[statement.status as keyof typeof hash] || '';
  };

  const refundStatementAction = (statement: Statement) => {
    setOpenRefundPopup(true);
    setSelectedStatement(statement);
  };

  const refundStatement = (status: string) => {
    setOpenRefundPopup(false);
    enqueueSnackbar('Estamos procesando tu solicitud', {
      variant: 'success',
    });
    sellerApi.statements
      .refund(selectedStatement?.id || '', selectedStatement?.group_id, status)
      .then((response) => {
        enqueueSnackbar(
          response.message === 'kushki'
            ? 'La devolución está siendo procesada por el proveedor'
            : status === 'refund'
            ? 'Pago reembolsado exitosamente'
            : 'Solicitud procesada con éxito',
          {
            variant: 'success',
          }
        );
      })
      .catch((reason) => {
        let text = '';
        if (reason.response.data.errors[0].title === 'Forbidden')
          text = 'Necesitas ser administrador para realizar esta acción';
        if (reason.response.data.message === 'fintoc not allowed')
          text = 'Fintoc no está habilitado para realizar devoluciones';
        if (reason.response.data.message === 'expired_time')
          text = 'Han pasado más de 14 días desde que se hizo el pago';
        if (reason.response.data.message === 'exceed_amount')
          text = 'No tienes saldo suficiente para devolver el monto';
        if (reason.response.data.message === 'not_credit_card')
          text = 'Solo se pueden hacer devoluciones de tarjetas de crédito';
        enqueueSnackbar(text || 'No pudimos devolver el pago, inténtalo más tarde', {
          variant: 'error',
        });
      })
      .finally(() => {
        setSelectedStatement(undefined);
      });
  };

  const statementPayemtTransactions = (res: Statement) => {
    setDrawerOpen(true);
    sellerApi.statements
      .paymentTransactions(res.id)
      .then((data) => {
        setPaymentTransactions(data.data);
      })
      .catch(() => {
        enqueueSnackbar('Error al cargar los intentos de cobro de la cuota', {
          variant: 'error',
        });
      });
  };

  const retryChargeAction = (statement: Statement) => {
    setSelectedStatement(statement);
    setOpenRetryChargePopup(true);
  };

  const retryCharge = () => {
    setOpenRetryChargePopup(false);
    enqueueSnackbar('Estamos procesando el cobro', {
      variant: 'success',
    });
    sellerApi.statements
      .retryCharge(selectedStatement?.id || '')
      .then(() => {
        enqueueSnackbar('Intento de cobro realizado exitosamente', {
          variant: 'success',
        });
        statementsRef?.current?.reloadResource?.();
      })
      .catch((reason) => {
        let text = '';
        if (reason.response.data.message === 'error_collecting')
          text = 'Intento de cobro rechazado';
        if (reason.response.data.message === 'daily_attempts_exceeded')
          text = 'Máximo un intento diario, inténtalo nuevamente otro día';
        if (reason.response.data.message === 'max_attempts_exceeded')
          text = 'Máximo intentos de cobros superado';
        enqueueSnackbar(text || 'No pudimos relizar el intento de cobro, inténtalo más tarde', {
          variant: 'error',
        });
      })
      .finally(() => {
        setSelectedStatement(undefined);
      });
  };

  useEffect(() => {
    if (dialogOpen === false) {
      setStatement(undefined);
    }
  }, [dialogOpen]);

  useEffect(() => {
    console.log(selectedStatement);
  }, [selectedStatement]);

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            {/* HEADER */}
            <Grid container>
              <Grid item xs={12}>
                <div className={cStyles.paperHeader}>
                  <Avatar
                    className={cStyles.paperHeaderAvatar}
                    text={paymentPlan?.product?.name}
                    img={paymentPlan?.product?.image}
                    context="product"
                  />
                  <div className={cStyles.paperHeaderContent}>
                    <Typography variant={isMobile ? 'h6' : 'h5'}>
                      <b>{paymentPlan?.product?.name}</b>
                    </Typography>
                    {!isMobile && (
                      <Typography variant="subtitle1">
                        {paymentPlan?.product?.description}
                      </Typography>
                    )}
                  </div>
                  {!company?.hide_payment_plan_edition && (
                    <div className={cStyles.paperHeaderActions}>
                      <div className={`${cStyles.baseAction} ${cStyles.editAction}`}>
                        <IconButton
                          size="medium"
                          disabled={loading}
                          component={Link}
                          to={`/seller/payment_plans/${paymentPlan?.id}/edit`}
                          className={`${cStyles.icon} ${cStyles.editIcon}`}
                        >
                          {loading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <FontAwesomeIcon icon={faPenToSquare} />
                          )}
                        </IconButton>
                        <Typography variant="body2">Editar</Typography>
                      </div>
                      <div className={`${cStyles.baseAction} ${cStyles.deleteAction}`}>
                        <IconButton
                          size="medium"
                          disabled={loading}
                          onClick={() => {
                            setDialogContext('deletePaymentPlan');
                            setOpenDialog(true);
                          }}
                          className={`${cStyles.icon} ${cStyles.deleteIcon}`}
                        >
                          {loading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <FontAwesomeIcon icon={faTrashCan} />
                          )}
                        </IconButton>
                        <Typography variant="body2">Eliminar</Typography>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid container className={cStyles.infoContainer}>
              {isMobile && paymentPlan?.product?.description ? (
                <Grid item xs={12}>
                  <Typography variant="caption">Descripción:</Typography>
                  <Typography variant="h6">{paymentPlan?.product?.description}</Typography>
                </Grid>
              ) : null}
              {Object.keys(paymentPlan?.product.extra_fields || {}).length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" className={styles.infoContainerTitle}>
                    Datos adicionales del plan de pago
                  </Typography>
                </Grid>
              )}
              {Object.keys(paymentPlan?.product.extra_fields || {}).map((key) => (
                <Grid item xs={6} md={4} key={key}>
                  <Typography variant="caption">
                    {paymentPlan?.product.extra_fields?.[key]}
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>{paymentPlan?.extra_fields[key]}</b>
                  </Typography>
                </Grid>
              ))}
              {paymentPlan?.collection_company_name && (
                <Grid item xs={12} mb={2}>
                  <Typography
                    variant="h6"
                    className={styles.infoContainerTitle}
                    style={{ marginBottom: '5px' }}
                  >
                    Proyecto
                  </Typography>
                  <Typography variant="subtitle1">
                    {paymentPlan?.collection_company_name}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" className={styles.infoContainerTitle}>
                  Datos del cliente
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="caption">Nombre del cliente</Typography>
                <Typography variant="subtitle1">
                  <b>{paymentPlan?.buyer.name}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="caption">Correo del cliente</Typography>
                <Typography variant="subtitle1">{paymentPlan?.buyer.email}</Typography>
              </Grid>
              {paymentPlan?.bank_information &&
                company?.default_bank_information?.id !== paymentPlan?.bank_information.id && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={styles.infoContainerTitle}>
                        Datos bancarios
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="caption">Nombre titular</Typography>
                      <Typography variant="subtitle1">
                        <b>{paymentPlan?.bank_information.name}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="caption">RUT</Typography>
                      <Typography variant="subtitle1">
                        {paymentPlan?.bank_information.tax_id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="caption">Banco</Typography>
                      <Typography variant="subtitle1">
                        {paymentPlan?.bank_information.bank}
                      </Typography>
                    </Grid>
                  </>
                )}
              {paymentPlan?.billing_information &&
                company?.default_billing_information?.id !==
                  paymentPlan?.billing_information.id && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={styles.infoContainerTitle}>
                        Datos de facturación
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="caption">Razón social</Typography>
                      <Typography variant="subtitle1">
                        <b>{paymentPlan?.billing_information.business_name}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="caption">RUT</Typography>
                      <Typography variant="subtitle1">
                        {paymentPlan?.billing_information.tax_id}
                      </Typography>
                    </Grid>
                  </>
                )}
              <Grid item xs={12} mt={2}>
                <Typography variant="caption">Link portal del cliente</Typography>
                <div className={cStyles.linkContainer}>
                  <IconButton
                    size="medium"
                    disabled={loading}
                    className={cStyles.copyIcon}
                    onClick={onLinkCopy}
                  >
                    {loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faCopy} />}
                  </IconButton>
                  <Typography variant="h6">
                    <a
                      className={styles.clientPortalLink}
                      href={portalLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {portalLink}
                    </a>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} mt={1}>
                <Typography variant="caption">Enviar correo a cliente con su portal</Typography>
                <Typography variant="subtitle1">
                  <Button className={styles.mailButton} onClick={() => setOpenSendEmail(true)}>
                    Enviar correo
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {isMobile ? (
        <Stepper assets={boxes(12).slice(0, -1)} />
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes().map((box) => box)}
        </Grid>
      )}

      {isMobile ? (
        <Button
          className={styles.toButton}
          onClick={() => {
            setOpenDialog(true);
            setDialogContext('create');
          }}
          variant="contained"
        >
          Agregar cuota
        </Button>
      ) : null}

      <ResourceList
        title="Cuotas"
        queryFields=""
        excelDownloadMethod={(_, parentId) =>
          sellerApi.paymentPlans.generateStatementsExcel(parentId)
        }
        getResourceList={(id, _, page) => sellerApi.paymentPlans.statements(id, page)}
        listHeaders={[
          { key: 'number', label: 'Cuota' },
          { key: 'amount', label: 'Monto' },
          { key: 'due_date', label: 'Fecha de vencimiento' },
          { key: 'payment_date', label: 'Fecha de pago' },
          { key: 'status', label: 'Estado' },
        ]}
        listColumns={{
          number: (res) => res.installment_number,
          amount: (res: Statement) =>
            formatCurrency(res.currency === 'UF' ? res.external_amount : res.amount, res.currency),
          due_date: (res) => res.due_date,
          payment_date: (res) => res.payment_date || '-',
          status: (res) => <StatusLabel type="statement" status={res.status} />,
          name: (res) => res.due_date,
        }}
        resourceParent={paymentPlan}
        innerRef={statementsRef}
        listActionsHeaders={(res) => {
          if (res) {
            return ['Fintoc', 'ExternalTransaction'].includes(res.payment_type || 'none') ||
              ['refunded', 'processing_payment', 'processing_refund', 'refunded_manually'].includes(
                res.status
              )
              ? [null]
              : statementStatusAction(res).map((resp: any) => ({
                  key: resp.action,
                  label: resp.label,
                  icon: resp.icon,
                }));
          }
          return [null];
        }}
        listActions={{
          edit: (res) => {
            setStatement(res);
            setDialogContext('edit');
            setOpenDialog(true);
          },
          delete: (res) => {
            setStatement(res);
            setDialogContext('deleteStatement');
            setOpenDialog(true);
          },
          refund: (res: Statement) => refundStatementAction(res),
          detail: (res: Statement) => statementPayemtTransactions(res),
          retry_charge: (res: Statement) => retryChargeAction(res),
        }}
        hideQuery
        listMobileHeaders={[{ key: 'name' }, { key: 'amount' }, { key: 'status' }]}
      />
      <StatementPopUp
        context={dialogContext}
        state={{ open: dialogOpen, setOpen: setOpenDialog }}
        innerRef={statementsRef}
        statement={statement}
        paymentPlanId={paymentPlanId}
        companyId={company?.id}
      />
      <PopUp
        state={{ open: openSendEmail, setOpen: setOpenSendEmail }}
        content={
          <Fragment>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Se enviará un correo con el link del portal del cliente a {paymentPlan?.buyer.email}.
            </Typography>
          </Fragment>
        }
        extraActions={[
          <Button onClick={() => setOpenSendEmail(false)} variant="outlined" key={2}>
            Cancelar
          </Button>,
          <Button
            onClick={() => {
              sendClientPortalEmail(company?.id || '', paymentPlan?.buyer.id || '');
              setOpenSendEmail(false);
            }}
            color="info"
            variant="contained"
            key={1}
          >
            Enviar
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: openRetryChargePopup, setOpen: setOpenRetryChargePopup }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se intentará cobrar {formatCurrency(selectedStatement?.amount)} a la tajeta terminada en
            •••• {selectedStatement?.payable_card} de tu cliente {selectedStatement?.buyer?.name}.
            ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => setOpenRetryChargePopup(false)} variant="outlined" key={2}>
            No
          </Button>,
          <Button onClick={retryCharge} color="error" variant="contained" key={1}>
            Si
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openRefundPopup, setOpen: setOpenRefundPopup }}
        title={
          <Typography variant="h6" align="center">
            Devolución pago
          </Typography>
        }
        content={
          <>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Para devolver un pago tienes dos opciones, que la plataforma procese la devolución a
              través del medio de pago con el que se realizó el pago. O marcar el pago como
              &quot;Devuelto manualmente&quot; y que tú gestiones la devolución por fuera de la
              plataforma.
            </Typography>
          </>
        }
        extraActions={[
          <Button onClick={() => setOpenRefundPopup(false)} variant="outlined" key={1}>
            Cancelar
          </Button>,
          <Button
            color="info"
            variant="contained"
            onClick={() => refundStatement('refund')}
            key={2}
          >
            Devolver cuota
          </Button>,
          <Button
            onClick={() => {
              refundStatement('refund_manually');
            }}
            color="info"
            variant="contained"
            key={3}
          >
            Marcar como devuelta
          </Button>,
        ]}
      />
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={styles.paymentsDrawer}>
          <div className={styles.closePopup}>
            <div className={styles.xIcon} onClick={() => setDrawerOpen(false)}>
              <FontAwesomeIcon
                icon={faXmark}
                style={{ color: '#5f5f5f', width: '20px', height: '20px' }}
              />
            </div>
          </div>
          <Typography variant="h6">
            <b>Intentos de cobro</b>
          </Typography>
          <TableList
            resources={paymentTransactions}
            headers={[
              { key: 'payment_date', label: 'Fecha' },
              { key: 'status', label: 'Estado' },
              { key: 'payment_method', label: 'Método de Pago' },
              { key: 'error', label: 'Descripción' },
            ]}
            columns={{
              payment_date: (res: PaymentTransaction) => res.payment_date,
              status: (res: PaymentTransaction) => (
                <StatusLabel type="paymentTransaction" status={res.status} />
              ),
              payment_method: (res: PaymentTransaction) => res.payment_method,
              error: (res: PaymentTransaction) => res.error || 'Sin información del proveedor',
            }}
          />
        </div>
      </Drawer>
    </>
  );
};

interface StatementPopUpProps {
  state: {
    open: boolean;
    setOpen: (s: boolean) => void;
  };
  statement?: Statement;
  paymentPlanId?: string;
  companyId?: string;
  context?: string;
  innerRef: React.MutableRefObject<{ reloadResource: () => void } | undefined>;
}

const StatementPopUp = (props: StatementPopUpProps): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const createStatement = (values: { [key: string]: string | number | Date | undefined }) => {
    sellerApi.paymentPlans
      .createStatement(props.paymentPlanId || '', { data: values })
      .then(() => {
        enqueueSnackbar('Cuota guardada correctamente', { variant: 'success' });
        props.innerRef.current?.reloadResource();
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
      });
  };

  const editStatement = (values: { [key: string]: string | number | Date | undefined }) => {
    sellerApi.paymentPlans
      .editStatement(props.paymentPlanId || '', values)
      .then(() => {
        enqueueSnackbar('Cuota actualizada correctamente', { variant: 'success' });
        props.innerRef.current?.reloadResource();
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
      });
  };

  const deleteStatement = (id: string) => {
    if (id && props.paymentPlanId) {
      sellerApi.paymentPlans
        .deleteStatement(props.paymentPlanId, id)
        .then(() => {
          enqueueSnackbar('Cuota borrada correctamente', { variant: 'success' });
          props.innerRef.current?.reloadResource();
        })
        .catch(() => {
          enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
        })
        .finally(() => props.state.setOpen(false));
    }
  };

  const deletePaymentPlan = () => {
    if (props.paymentPlanId) {
      sellerApi.paymentPlans
        .deletePaymentPlan(props.paymentPlanId)
        .then(() => {
          enqueueSnackbar('Plan de pago eliminado correctamente', { variant: 'success' });
          history.push('/seller/payment_plans');
        })
        .catch((error) => {
          if (error.response.status === 500) {
            enqueueSnackbar('No puedes eliminar un plan de pagos que tenga cuotas pagadas', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ha ocurrido un error, intenta nuevamente', { variant: 'error' });
          }
        })
        .finally(() => props.state.setOpen(false));
    }
  };

  const formik = useFormik({
    initialValues: {
      id: props.statement?.id || undefined,
      amount: props.statement?.amount || 0,
      currency: props.statement?.currency || 'CLP',
      due_date: props.statement?.due_date || undefined,
      company_id: props.companyId,
      external_amount: props.statement?.external_amount || 0,
    },
    onSubmit: (values) => {
      if (props.context === 'create') {
        createStatement(values);
      } else if (props.context === 'edit') {
        editStatement(values);
      }
      props.state.setOpen(false);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (props.statement) {
      Object.keys(formik.values).forEach((key: string) => {
        if (key !== 'due_date') {
          formik.setFieldValue(key, props.statement?.[key as keyof Statement] || undefined);
        } else {
          const date = DateTime.fromFormat(
            props.statement?.due_date || '',
            'dd/MM/yyyy'
          ).toISODate();
          formik.setFieldValue('due_date', date);
        }
      });
    } else {
      formik.resetForm();
    }
  }, [props.statement]);

  const render = () => {
    if (props.context === 'create') {
      return (
        <div className={styles.statementForm}>
          <Typography>Crear nueva cuota</Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
            className={styles.form}
          >
            <TextField
              select
              required
              value={formik.values.currency}
              id="currency"
              variant="outlined"
              autoComplete="currency"
              label="Moneda"
              onChange={(event) => formik.setFieldValue('currency', event.target.value)}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            >
              {CURRENCIES.map((curr: string) => (
                <MenuItem key={curr} value={curr}>
                  {curr}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              required
              label="Monto"
              value={
                formik.values.currency === 'UF'
                  ? formatDecimalNumber(formik.values.external_amount)
                  : formatNumber(formik.values.external_amount)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {formik.values.currency === 'CLP' ? '$' : null}
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                const amount =
                  formik.values.currency === 'UF'
                    ? decimalNumberFormatter(event.target.value)
                    : parseInt(plainNumberFormatter(event.target.value)) || 0;
                formik.setFieldValue('amount', amount);
                formik.setFieldValue('external_amount', amount);
              }}
              id="amount"
              autoComplete="amount"
              variant="outlined"
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
            <TextField
              required
              label="Fecha de vencimiento"
              value={formik.values.due_date}
              onChange={formik.handleChange}
              id="due_date"
              type="date"
              autoComplete="due_date"
              variant="outlined"
              error={formik.touched.due_date && Boolean(formik.errors.due_date)}
              helperText={formik.touched.due_date && formik.errors.due_date}
              InputLabelProps={{ shrink: true }}
            />
            <div className={styles.actionsEdit}>
              <Button variant="outlined" onClick={() => props.state.setOpen(false)}>
                Cancelar
              </Button>
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            </div>
          </form>
        </div>
      );
    } else if (props.context === 'edit') {
      return (
        <div className={styles.statementForm}>
          <Typography>Editar cuota</Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
            className={styles.form}
          >
            <TextField
              select
              required
              value={formik.values.currency}
              id="currency"
              variant="outlined"
              autoComplete="currency"
              label="Moneda"
              onChange={(event) => formik.setFieldValue('currency', event.target.value)}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            >
              {CURRENCIES.map((curr: string) => (
                <MenuItem key={curr} value={curr}>
                  {curr}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              required
              label="Monto"
              value={
                formik.values.currency === 'UF'
                  ? formatDecimalNumber(formik.values.external_amount)
                  : formatNumber(formik.values.external_amount)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {formik.values.currency === 'CLP' ? '$' : null}
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                const amount =
                  formik.values.currency === 'UF'
                    ? decimalNumberFormatter(event.target.value)
                    : plainNumberFormatter(event.target.value);
                formik.setFieldValue('amount', amount);
                formik.setFieldValue('external_amount', amount);
              }}
              id="amount"
              autoComplete="amount"
              variant="outlined"
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
            <TextField
              required
              label="Fecha de vencimiento"
              value={formik.values.due_date}
              onChange={formik.handleChange}
              id="due_date"
              type="date"
              autoComplete="due_date"
              variant="outlined"
              error={formik.touched.due_date && Boolean(formik.errors.due_date)}
              helperText={formik.touched.due_date && formik.errors.due_date}
              InputLabelProps={{ shrink: true }}
            />
            <div className={styles.actionsEdit}>
              <Button variant="outlined" onClick={() => props.state.setOpen(false)}>
                Cancelar
              </Button>
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            </div>
          </form>
        </div>
      );
    } else if (props.context === 'deleteStatement') {
      return (
        <>
          <Typography>
            ¿Estás seguro que quieres eliminar esta cuota? Esta acción es irreversible
          </Typography>
          <div className={styles.actionsEdit}>
            <Button key={1} variant="outlined" onClick={() => props.state.setOpen(false)}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={() => deleteStatement(props.statement?.id || '')}>
              Sí
            </Button>
          </div>
        </>
      );
    } else if (props.context === 'deletePaymentPlan') {
      return (
        <>
          <Typography>
            ¿Estás seguro que quieres eliminar este plan de pago? Esta acción es irreversible
          </Typography>
          <div>
            <Button key={1} variant="outlined" onClick={() => props.state.setOpen(false)}>
              Cancelar
            </Button>
            <Button key={2} variant="contained" onClick={deletePaymentPlan}>
              Sí
            </Button>
          </div>
        </>
      );
    }
    return <div></div>;
  };

  return <PopUp content={render()} state={props.state} />;
};

export default Show;

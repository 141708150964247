import React, { useState, useEffect } from 'react';
import PopUp from '../PopUp';
import { InfoButton } from '../InfoButton';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import styles from './Filter.module.scss';
import { Filter } from 'react-feather';
import { formatCLP } from '../../utils';

import { P_STATUS_ES as PRODUCT_STATUS } from '../../constants/products';
import { STATUS_ES as SUBSCRIPTION_STATUS, RECURRENCE_ES } from '../../constants/subscriptions';
import { STATUS_ES as SINGLE_PAYMENT_STATUS } from '../../constants/singlePayments';
import { STATUS_ES as SUBSCRIPTION_BUYER_STATUS } from '../../constants/subscriptionBuyers';
import { STATUS_ES as SINGLE_PAYMENT_BUYER_STATUS } from '../../constants/singlePaymentBuyers';
import {
  STATUS_ES as STATEMENT_STATUS,
  PAYABLE_TYPES as STATEMENT_TYPE,
} from '../../constants/statements';

interface FilterPopupProps {
  filters: string[];
  displayData: { [key: string]: any };
  setDisplayData: (args: { [key: string]: any }) => void;
  popupRef?: React.MutableRefObject<HTMLButtonElement | null>;
}

export const FilterPopup = (props: FilterPopupProps): React.ReactElement => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [newFilters, setNewFilters] = useState<{ [key: string]: any }>({});

  const handleInputChange = (context: string, value: any, innerContext?: string) => {
    if (innerContext) {
      setNewFilters({
        ...newFilters,
        [context]: { ...newFilters[context], [innerContext]: value },
      });
      return;
    }
    setNewFilters({ ...newFilters, [context]: value });
  };

  useEffect(() => {
    setNewFilters(props.displayData);
  }, [props.displayData]);

  const applyFilters = () => {
    props.setDisplayData(newFilters);
    setOpenPopup(false);
  };

  const titleRender: React.ReactElement = (
    <div className={styles.titleContainer}>
      <div className={styles.emptyDiv}></div>
      <Typography className={styles.title}>Filtros</Typography>
      <div className={styles.emptyDiv}></div>
    </div>
  );

  const contentRender: React.ReactElement = (
    <>
      {props.filters.includes('price') && (
        <SliderFilterRender
          title="Valor"
          context="price"
          displayData={props.displayData.price || {}}
          setDisplayData={handleInputChange}
        />
      )}
      {props.filters.includes('status') &&
        Object.keys(props.displayData.status || {}).map(
          (key, index) =>
            Object.keys(props.displayData.status[key]).length !== 1 && (
              <StateFilterRender
                title="Estado"
                context="status"
                displayData={props.displayData.status[key] || {}}
                setDisplayData={handleInputChange}
                previousContext={key}
                key={`filterPopup-${index}`}
              />
            )
        )}
      {props.filters.includes('payable_type') &&
        Object.keys(props.displayData.payable_type).length !== 1 && (
          <StateFilterRender
            title="Tipo"
            context="payable_type"
            displayData={props.displayData.payable_type || {}}
            setDisplayData={handleInputChange}
          />
        )}
      {props.filters.includes('recurrence') &&
        Object.keys(props.displayData.recurrence).length !== 1 && (
          <StateFilterRender
            title="Recurrencia"
            context="recurrence"
            displayData={props.displayData.recurrence || {}}
            setDisplayData={handleInputChange}
          />
        )}
      {props.filters.includes('subscription_buyer_status') &&
        Object.keys(props.displayData.subscription_buyer_status).length !== 1 && (
          <StateFilterRender
            title="Estado inscripción"
            context="subscription_buyer_status"
            displayData={props.displayData.subscription_buyer_status || {}}
            setDisplayData={handleInputChange}
          />
        )}
      {props.filters.includes('payment_date') && (
        <DateFilterRender
          title="Fecha de Pago"
          context="payment_date"
          displayData={props.displayData.payment_date || {}}
          setDisplayData={handleInputChange}
        />
      )}
      {props.filters.includes('due_date') && (
        <DateFilterRender
          title="Fecha de Vencimiento"
          context="due_date"
          displayData={props.displayData.due_date || {}}
          setDisplayData={handleInputChange}
        />
      )}
      {props.filters.includes('subscription_date') && (
        <DateFilterRender
          title="Fecha de Suscripción"
          context="subscription_date"
          displayData={props.displayData.subscription_date || {}}
          setDisplayData={handleInputChange}
        />
      )}
      {props.filters.includes('created_at') && (
        <DateFilterRender
          title="Fecha de Creación"
          context="created_at"
          displayData={props.displayData.created_at || {}}
          setDisplayData={handleInputChange}
        />
      )}
    </>
  );

  const extraActionsRender: React.ReactElement[] = [
    <Button variant="contained" className={styles.saveButton} key={0} onClick={applyFilters}>
      <Typography className={styles.text}>Guardar</Typography>
    </Button>,
  ];

  return (
    <div>
      <Button
        variant="outlined"
        className={styles.openPopupButton}
        onClick={() => setOpenPopup(true)}
        ref={props.popupRef}
      >
        <Filter />
        <Typography>Filtrar</Typography>
      </Button>
      <PopUp
        state={{ open: openPopup, setOpen: setOpenPopup }}
        title={titleRender}
        content={contentRender}
        extraActions={extraActionsRender}
      />
    </div>
  );
};

interface SliderFilterRenderProps {
  title: string;
  context: string;
  displayData: { [key: string]: number };
  setDisplayData: (context: string, value: any) => void;
}

const SliderFilterRender = (props: SliderFilterRenderProps): React.ReactElement => {
  const [sliderValues, setSliderValues] = useState<number[]>([
    props.displayData.min || props.displayData.renderMin,
    props.displayData.max || props.displayData.renderMax,
  ]);

  useEffect(() => {
    props.setDisplayData(props.context, {
      ...props.displayData,
      min: sliderValues[0],
      max: sliderValues[1],
    });
  }, [sliderValues]);

  return (
    <div className={styles.sliderContainer}>
      <Typography className={styles.title}>{props.title}</Typography>
      <Slider
        min={props.displayData.renderMin}
        max={props.displayData.renderMax}
        value={sliderValues}
        valueLabelDisplay="on"
        valueLabelFormat={(value) => formatCLP(value)}
        className={styles.slider}
        marks={[
          { value: props.displayData.renderMin, label: formatCLP(props.displayData.renderMin) },
          { value: props.displayData.renderMax, label: formatCLP(props.displayData.renderMax) },
        ]}
        onChange={(_, value) => {
          setSliderValues(value as number[]);
        }}
      />
      <Divider />
    </div>
  );
};

interface StateFilterRenderProps {
  title: string;
  context: string;
  displayData: { [key: string]: any };
  setDisplayData: (context: string, value: any, previousContext?: string) => void;
  previousContext?: string;
}

const StateFilterRender = (props: StateFilterRenderProps): React.ReactElement => {
  const [auxiliarData, setAuxiliarData] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (props.previousContext) {
      setAuxiliarData(props.displayData[props.previousContext]);
    }
    setAuxiliarData(props.displayData);
  }, [props.previousContext, props.displayData]);

  const setChange = (newState: boolean, updateValue: string) => {
    let cleanObject = { ...auxiliarData, [updateValue]: newState ? 'activated' : 'deactivated' };
    if (
      Object.values(cleanObject).some((value) => value !== 'off') &&
      Object.values(cleanObject).some((value) => value === 'off')
    ) {
      cleanObject = Object.keys(auxiliarData).reduce((previous, current) => {
        if (cleanObject[current] === 'off') {
          return { ...previous, [current]: 'deactivated' };
        }
        return { ...previous, [current]: cleanObject[current] };
      }, {});
    }
    setAuxiliarData(cleanObject);
  };

  useEffect(() => {
    if (props.previousContext) {
      props.setDisplayData(props.context, auxiliarData, props.previousContext);
      return;
    }
    props.setDisplayData(props.context, auxiliarData);
  }, [auxiliarData]);

  const dictToUse = () => {
    if (props.context === 'recurrence') {
      return RECURRENCE_ES;
    } else if (props.context === 'payable_type') {
      return STATEMENT_TYPE;
    } else if (props.context === 'subscription_buyer_status') {
      return SUBSCRIPTION_BUYER_STATUS;
    } else {
      // Se asume solo status por ahora
      if (props.previousContext === 'product') {
        return PRODUCT_STATUS;
      } else if (props.previousContext === 'subscription') {
        return SUBSCRIPTION_STATUS;
      } else if (props.previousContext === 'single_payment') {
        return SINGLE_PAYMENT_STATUS;
      } else if (props.previousContext === 'subscription_buyer') {
        return SUBSCRIPTION_BUYER_STATUS;
      } else if (props.previousContext === 'single_payment_buyer') {
        return SINGLE_PAYMENT_BUYER_STATUS;
      } else {
        return STATEMENT_STATUS;
      }
    }
  };

  return (
    <div className={styles.statesContainer}>
      <div style={{ display: 'flex', alignItems: 'Center' }}>
        <Typography className={styles.title}>{props.title}</Typography>
        {props.context === 'subscription_buyer_status' && (
          <InfoButton text="general_status" context="subscriptionBuyer" />
        )}
      </div>
      <div className={styles.boxesContainer}>
        {Object.keys(auxiliarData).map((key) => (
          <div
            className={
              auxiliarData[key] === 'activated' ? styles.activatedBox : styles.deactivatedBox
            }
            key={`stateFilter-${key}`}
          >
            <Checkbox
              checked={auxiliarData[key] === 'activated'}
              onChange={(_, newState) => setChange(newState, key)}
            />
            <Typography>{dictToUse()[key]}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

interface DateFilterRenderProps {
  title: string;
  context: string;
  displayData: { [key: string]: string };
  setDisplayData: (context: string, value: any) => void;
}

const DateFilterRender = (props: DateFilterRenderProps): React.ReactElement => {
  const [dates, setDates] = useState<{ [key: string]: string }>({
    start_date: props.displayData.start_date,
    finish_date: props.displayData.finish_date,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: any, context: string) => {
    if (context === 'start') {
      if (dates.finish_date == (undefined || null)) {
        setDates({ ...dates, start_date: event.target.value });
        return;
      } else {
        if (new Date(event.target.value) > new Date(dates.finish_date)) {
          enqueueSnackbar('La fecha de inicio debe ser menor o igual a la fecha de término.', {
            variant: 'warning',
          });
          return;
        }
        setDates({ ...dates, start_date: event.target.value });
      }
    } else {
      if (dates.start_date == (undefined || null)) {
        setDates({ ...dates, finish_date: event.target.value });
        return;
      } else {
        if (new Date(event.target.value) < new Date(dates.start_date)) {
          enqueueSnackbar('La fecha de término debe ser mayor o igual a la fecha de inicio.', {
            variant: 'warning',
          });
          return;
        }
        setDates({ ...dates, finish_date: event.target.value });
      }
    }
  };

  useEffect(() => {
    props.setDisplayData(props.context, dates);
  }, [dates]);

  return (
    <div className={styles.datesContainer}>
      <Typography className={styles.title}>{props.title}</Typography>
      <div className={styles.dates}>
        <TextField
          type="date"
          label="Inicio"
          InputLabelProps={{ shrink: true }}
          value={dates.start_date}
          onChange={(event) => handleChange(event, 'start')}
        />
        <TextField
          type="date"
          label="Fin"
          InputLabelProps={{ shrink: true }}
          value={dates.finish_date}
          onChange={(event) => handleChange(event, 'finish')}
        />
      </div>
    </div>
  );
};

export default FilterPopup;

import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import variables from '../styles/variables.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from '../../app/hooks';
import lodash from 'lodash';

// Types
import { ApiPagination, ApiList } from '../../app/type';
import { ResourceListProps, Header } from './ResourceListTypes';

// Components
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  CircularProgress,
  IconButton,
} from '@mui/material';

import { Download } from 'react-feather';

import TableList from './TableList';
import BoxList from './BoxList';
import Pagination from './Pagination';
import DescriptionsBox from './DescriptionsBox';

// Assets
import styles from './ResourceList.module.scss';
import cStyles from '../styles/common.module.scss';
import { Filter as FilterIcon } from 'react-feather';

// Snackbar
import { useSnackbar } from 'notistack';
import Filter from './Filter/Filter';
import { getItem, setItem } from '../utils/localstorage';

let queryTimeout: ReturnType<typeof setTimeout>;

const ResourceList = <T, R>({
  title,
  queryFields,
  headerActions,
  setResource,
  getResourceList,
  listHeaders,
  listMobileHeaders,
  listColumns,
  resourceParent,
  innerRef,
  className,
  listClassName,
  defaultRoute,
  excelDownloadMethod,
  moreInfoContext,
  listActions,
  listActionsHeaders,
  chips,
  msgOnError,
  alternativeStyle,
  getCollapseResources,
  listCollapseHeaders,
  listCollapseColumns,
  listMobileCollapseHeaders,
  filtersKey,
  persistFilters,
  listCollapseActionsHeaders,
  listCollapseActions,
  hideQuery,
}: ResourceListProps<T, R>): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [resources, setResources] = useState<T[]>([]);
  const [query, setQuery] = useState<string>(
    (persistFilters && filtersKey && getItem('query')?.[filtersKey]?.query) || ''
  );
  const [loading, setLoading] = useState<boolean>(false);
  const localStoragePagination: ApiPagination =
    (persistFilters && filtersKey && getItem('pagination')[filtersKey]) || {};
  const [page, setPage] = useState<ApiPagination>(
    localStoragePagination.current ? localStoragePagination : { current: 1, pages: 1 }
  );
  const [filterParams, setFilterParams] = useState<{ [key: string]: any }>({});
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [updatedListHeaders, setUpdatedListHeaders] = useState<(Header | null)[]>(listHeaders);
  const popupRef = useRef<HTMLButtonElement>(null);

  const loadResource = (
    currentPage: number,
    query: string,
    filterParams?: { [key: string]: any }
  ) => {
    if (resourceParent) {
      setLoading(true);
      if (setResource) dispatch(setResource(undefined));
      getResourceList(resourceParent.id, query, currentPage, filterParams)
        .then((data: ApiList<T>) => {
          setResources(data.data);
          if (data.meta) {
            if (page.current > data.meta.pages) {
              setPage({ ...data.meta, current: 1 });
              setLocalStorage({ ...data.meta, current: 1 }, 'pagination');
            } else {
              setPage(data.meta);
            }
          }
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (!msgOnError) return null;
      history.replace(defaultRoute || '/seller/home');
    }
  };

  const excelResource = () => {
    setLoading(true);
    excelDownloadMethod?.(query || '', resourceParent?.id, filterParams)
      .then(() => {
        enqueueSnackbar('Se enviará un mail con el excel generado', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Hubo un error al crear el archivo', { variant: 'error' }))
      .finally(() => {
        setLoading(false);
      });
  };

  useImperativeHandle(innerRef, () => ({
    reloadResource() {
      loadResource(page.current, query, filterParams);
    },
  }));

  useEffect(() => {
    if (Object.keys(filterParams).length > 0) {
      loadResource(page.current, query, filterParams);
    }
  }, [resourceParent?.id]);

  const loadFilters = (query: string, filterParams?: { [key: string]: any }) => {
    if (filterParams) setFilterParams(filterParams);
    loadResource(page.current, query, filterParams);
  };

  useEffect(() => {
    if (queryTimeout) clearTimeout(queryTimeout);
  }, []);

  const handleChipClick = (chip: Header) => () => {
    const actualState = [...updatedListHeaders];
    if (updatedListHeaders.includes(chip)) {
      lodash.pull(actualState, chip);
      setUpdatedListHeaders([...actualState]);
    } else {
      setUpdatedListHeaders([...actualState, chip]);
    }
  };

  const setLocalStorage = (args: { [key: string]: any }, context = 'filter') => {
    persistFilters && filtersKey && setItem(context, { ...getItem(context), [filtersKey]: args });
  };

  return isMobile ? (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={cStyles.infoPaper}>
          <div className={styles.mobileDisplay}>
            <div className={styles.mobileHeader}>
              <Typography variant="h6">
                <b>{title}</b>
              </Typography>
              <div>
                {excelDownloadMethod && (
                  <IconButton
                    size="medium"
                    className={styles.downloadButton}
                    onClick={excelResource}
                  >
                    {loading ? <CircularProgress size={20} /> : <Download />}
                  </IconButton>
                )}
                {Object.keys((filtersKey && getItem('filter')[filtersKey]) || {}).length ? (
                  <IconButton
                    size="medium"
                    className={styles.filterButton}
                    onClick={() => popupRef.current?.click()}
                  >
                    {loading ? <CircularProgress size={20} /> : <FilterIcon />}
                  </IconButton>
                ) : null}
              </div>
            </div>
            {!hideQuery && (
              <Filter
                filters={Object.keys((filtersKey && getItem('filter')[filtersKey]) || {})}
                displayData={filtersKey && getItem('filter')[filtersKey]}
                reloadResource={loadFilters}
                setLocalStorage={setLocalStorage}
                queryState={[query, setQuery]}
                queryFields={queryFields}
                popupRef={popupRef}
              />
            )}
          </div>
        </Paper>
        {/* DATA */}
        <BoxList
          loading={loading}
          resources={resources}
          listActions={listActions}
          listActionsHeaders={listActionsHeaders}
          headers={listMobileHeaders}
          columns={listColumns}
          getCollapseResources={getCollapseResources}
          listCollapseHeaders={listMobileCollapseHeaders}
          listCollapseColumns={listCollapseColumns}
        />
        <div className={styles.mobileListPagination}>
          <Pagination
            page={page}
            onChange={(current: number) => {
              setPage((p) => ({ ...p, current }));
              setLocalStorage({ ...page, current: current }, 'pagination');
              loadResource(current, query, filterParams);
            }}
            disabled={loading}
            isMobile={isMobile}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={3} sx={{ mb: 3 }} className={className}>
      <Grid item xs={12}>
        <Paper className={cStyles.infoPaper}>
          <Grid container spacing={2} className={styles.table}>
            <Grid item xs={12} className={styles.tableHeader}>
              <div className={styles.title}>
                <Typography variant={'h5'}>{title}</Typography>
                {excelDownloadMethod && (
                  <Typography variant={'body1'}>
                    <a onClick={excelResource}>
                      <u>Descargar Excel</u>
                    </a>
                  </Typography>
                )}
              </div>
              {!hideQuery && (
                <Filter
                  filters={Object.keys((filtersKey && getItem('filter')[filtersKey]) || {})}
                  displayData={filtersKey && getItem('filter')[filtersKey]}
                  reloadResource={loadFilters}
                  setLocalStorage={setLocalStorage}
                  queryState={[query, setQuery]}
                  queryFields={queryFields}
                />
              )}
              <div className={styles.actions}>
                {headerActions && headerActions(loading, excelResource, isMobile)}
              </div>
            </Grid>

            {moreInfoContext && (
              <Grid item xs={12}>
                <DescriptionsBox context={moreInfoContext} />
              </Grid>
            )}

            {moreInfoContext && (
              <Grid item xs={12}>
                <DescriptionsBox context={moreInfoContext} />
              </Grid>
            )}
            {/* LIST */}
            <Grid item xs={12}>
              {alternativeStyle ? (
                <BoxList
                  loading={loading}
                  resources={resources}
                  listActions={listActions}
                  listActionsHeaders={listActionsHeaders}
                  headers={updatedListHeaders}
                  columns={listColumns}
                  alternativeStyle={alternativeStyle}
                />
              ) : (
                <TableList
                  className={listClassName}
                  loading={loading}
                  resources={resources}
                  headers={updatedListHeaders}
                  columns={listColumns}
                  listActions={listActions}
                  listActionsHeaders={listActionsHeaders}
                  msgOnError={msgOnError}
                  getCollapseResources={getCollapseResources}
                  listCollapseHeaders={listCollapseHeaders}
                  listCollapseColumns={listCollapseColumns}
                  chips={chips}
                  handleChipClick={handleChipClick}
                  listCollapseActionsHeaders={listCollapseActionsHeaders}
                  listCollapseActions={listCollapseActions}
                />
              )}
              <div className={styles.tablePagination}>
                <Pagination
                  page={page}
                  onChange={(current: number) => {
                    setPage((p) => ({ ...p, current }));
                    setLocalStorage({ ...page, current: current }, 'pagination');
                    loadResource(current, query, filterParams);
                  }}
                  disabled={loading}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ResourceList;

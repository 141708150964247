// React, packages and api
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { sellerApi } from '../../../common/api';
import { useFormik, getIn } from 'formik';
import { omit } from 'lodash';

// Slice and utils
import { SellerState, setSinglePayment } from '../sellerSlice';
import { useDispatch, useSelector } from '../../../app/hooks';
import {
  setDocumentTitle,
  formatNumber,
  formatDecimalNumber,
  decimalNumberFormatter,
  plainNumberFormatter,
} from '../../../common/utils';

// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

// Styles and assets
import styles from './Edit.module.scss';
import { Trash as TrashIcon } from 'react-feather';
import { CURRENCIES } from '../../../common/constants/currencies';
import { DESCRIPTIONS } from '../../../common/constants/descriptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

// Componentes
import PopUp from '../../../common/components/PopUp';
import ShippingOptions from '../../../common/components/ShippingOptions';

// Types
import { ApiObject, DefaultExtraField, SinglePayment } from '../../../app/type';
import { InfoButton } from '../../../common/components/InfoButton';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { generateImage } from '../../../common/utils/cropImage';
import {
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Slider,
  Box,
} from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { FRONT_URL } from '../../../common/api/client';

const EditSinglePayment = (): React.ReactElement => {
  const { singlePayment } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const dispatch = useDispatch();
  const history = useHistory();
  const { singlePaymentId } = useParams<{ singlePaymentId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (singlePaymentId !== singlePayment?.id && singlePaymentId) {
      sellerApi.singlePayments
        .show(singlePaymentId)
        .then((data: ApiObject<SinglePayment>) => {
          dispatch(setSinglePayment(data.data));
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
        });
    }
  }, [singlePaymentId, singlePayment]);

  const onSuccess = (data: ApiObject<SinglePayment>) => {
    enqueueSnackbar('Servicio guardado exitosamente', { variant: 'success' });
    dispatch(setSinglePayment(data.data));
    history.push(`/seller/single_payments/${data.data.id}`);
  };

  singlePaymentId ? setDocumentTitle('Editar Pago Único') : setDocumentTitle('Crear Pago Único');

  return (
    <>
      <Grid container sx={{ width: 'auto', margin: '2%' }}>
        <Grid item container className={styles.paperHeader}>
          <Grid container item className={styles.paperHeaderContent}>
            <Grid item>
              <Typography variant="h5">
                {singlePaymentId ? 'Editar' : 'Crear'} pago único
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={styles.paperHeaderContent}>
            <Typography variant="body1">
              Los <b>pagos únicos</b> permiten a tus clientes pagar de manera rápida y sencilla.
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={styles.formContainer}>
          <Paper className={styles.infoPaper}>
            <SinglePaymentForm initialData={singlePayment} onSuccess={onSuccess} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

interface SinglePaymentFormProps {
  initialData: SinglePayment | undefined;
  onSuccess: (data: ApiObject<SinglePayment>) => void;
}

interface SinglePaymentInfo {
  single_payment: Partial<Omit<SinglePayment, 'products'>>;
  products: {
    id: string;
    quantity: number;
    price: number;
    name: string;
    company_id?: string;
    external_id?: string;
  }[];
  product: { name?: string; value?: number; currency?: string; external_id?: string };
}

let queryTimeout: ReturnType<typeof setTimeout>;

const SinglePaymentForm = (props: SinglePaymentFormProps): React.ReactElement => {
  const [options, setOptions] = useState<
    { title: string; groupLetter: string; id: string; price: number; external_id: string }[]
  >([]);
  const { company, singlePayment } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [loadingProducts, setLoadingProducts] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledFields, setDisabledFields] = useState<{ [key: string]: boolean }>({});
  const { enqueueSnackbar } = useSnackbar();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState<string>('');
  const [croppedArea, setCroppedArea] = React.useState<Area>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [defaultExtraFields, setDefaultExtraFields] = useState<DefaultExtraField[]>();
  const [inputValue, setInputValue] = useState<string>('');
  const [optionsDrawerOpen, setOptionsDrawerOpen] = useState<boolean>(false);
  const [selectedField, setSelectedField] = useState<string>('');
  const [shippingModalOpen, setShippingModalOpen] = useState<boolean>(false);

  const initialData = {
    single_payment: {
      id: props.initialData?.id,
      name: props.initialData?.name || '',
      status: props.initialData?.status || '',
      stock_limit: props.initialData?.stock_limit || null,
      limit_date: props.initialData?.limit_date || '',
      is_basketable: props.initialData?.is_basketable || false,
      currency: props.initialData?.currency || 'CLP',
      extra_fields: props.initialData?.extra_fields || {},
      mandatory_extra_fields: props.initialData?.mandatory_extra_fields || {},
      default_extra_fields: props.initialData?.default_extra_fields || {},
      extra_fields_kind: props.initialData?.extra_fields_kind || {},
      extra_fields_options: props.initialData?.extra_fields_options || {},
      company_id: company?.id || '',
      image: props.initialData?.image || '',
      description: props.initialData?.description || '',
      ask_shipping_address: props.initialData?.ask_shipping_address || false,
      shipping_config: props.initialData?.shipping_config || null,
    },
    products:
      props.initialData?.single_payment_products.length === 1 &&
      props.initialData?.single_payment_products[0].product.kind !== 'common'
        ? []
        : props.initialData?.single_payment_products.map((subProd) => ({
            id: subProd.product.id,
            quantity: subProd.quantity,
            price: subProd.product.value,
            company_id: company?.id || '',
            name: subProd.product.name || '',
          })) || [],
    product: {
      id: props.initialData?.single_payment_products[0].product.id,
      currency: props.initialData?.single_payment_products[0].product.currency || 'CLP',
      company_id: company?.id || '',
      value: props.initialData?.single_payment_products[0].value,
    },
  };

  const formik = useFormik<SinglePaymentInfo>({
    initialValues: initialData,
    onSubmit: (singelPaymentInfo: SinglePaymentInfo, { setErrors }: any) => {
      setLoading(true);
      const request = props.initialData?.id
        ? sellerApi.singlePayments.update
        : sellerApi.singlePayments.create;
      request(singelPaymentInfo)
        .then(props.onSuccess)
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          } else if (err?.response?.status === 403) {
            enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        })
        .finally(() => setLoading(false));
    },
  });

  const getOptions = (_: any, value: string, id: string) => {
    if (company?.id) {
      sellerApi.products
        .list(company.id, value, 1, {
          status: { product: { active: 'activated', inactive: 'deactivated' } },
        })
        .then((data) => {
          const newOptions = data.data.reduce(
            (
              prev: {
                title: string;
                groupLetter: string;
                id: string;
                price: number;
                external_id: string;
              }[],
              curr
            ) =>
              formik.values.products.some((p) => p.id === curr.id)
                ? prev
                : [
                    ...prev,
                    {
                      title: curr.name,
                      groupLetter: Array.from(curr.name)[0].toUpperCase(),
                      id: curr.id,
                      price: curr.value,
                      external_id: curr.external_id,
                    },
                  ],
            []
          );
          setOptions(newOptions);
          setLoadingProducts({ [id]: false });
        });
    }
  };

  const handleQueryChange = (_: any, value: string, id: string) => {
    if (queryTimeout) clearTimeout(queryTimeout);

    setLoadingProducts({ [id]: true });
    queryTimeout = setTimeout(() => {
      getOptions(_, value, id);
    }, 1000);
  };

  const setProduct = (
    _: any,
    value: { title: string; groupLetter: string; id: string; price: number } | null,
    index: number,
    id: string,
    quantity: number
  ) => {
    const temporaryProducts = [...formik.values.products];
    temporaryProducts[index] = {
      id: value?.id || id,
      quantity: quantity,
      price: value?.price || 0,
      name: value?.title || '',
    };
    formik.setFieldValue('products', temporaryProducts);
  };

  const addProduct = () => {
    getOptions('', '', '');
    formik.setFieldValue('products', [
      ...formik.values.products,
      { id: Date.now(), quantity: 1, price: 0 },
    ]);
    if (!disabledFields.value) setDisabledFields({ ...disabledFields, value: true });
  };

  const changeProductQty = (index: number, change: 'minus' | 'plus') => {
    const temporaryProducts = [...formik.values.products];
    if (change === 'minus') {
      temporaryProducts[index] = {
        ...temporaryProducts[index],
        quantity: temporaryProducts[index].quantity - 1,
      };
    } else if (change === 'plus') {
      temporaryProducts[index] = {
        ...temporaryProducts[index],
        quantity: temporaryProducts[index].quantity + 1,
      };
    }
    formik.setFieldValue('products', temporaryProducts);
  };

  const deleteProduct = (index: number) => {
    const temporaryProducts = [...formik.values.products];
    temporaryProducts.splice(index, 1);
    formik.setFieldValue('products', temporaryProducts);
  };

  useEffect(() => {
    if (formik.values.products.length === 0) setDisabledFields({ ...disabledFields, value: false });
    else setDisabledFields({ ...disabledFields, value: true });
  }, [formik.values.products]);

  const eraseExtraField = (id: string) => {
    formik.setFieldValue(
      'single_payment.extra_fields',
      omit(formik.values.single_payment.extra_fields, id)
    );
    formik.setFieldValue(
      'single_payment.mandatory_extra_fields',
      omit(formik.values.single_payment.mandatory_extra_fields, id)
    );
  };

  const addExtraField = () => {
    const date = Date.now();
    if (formik.values.single_payment.extra_fields) {
      formik.setFieldValue('single_payment.extra_fields', {
        ...formik.values.single_payment.extra_fields,
        [date]: '',
      });
      formik.setFieldValue('single_payment.mandatory_extra_fields', {
        ...formik.values.single_payment.mandatory_extra_fields,
        [date]: false,
      });
    }
  };

  const changeMandatoryField = (checked: boolean, id: string) => {
    formik.setFieldValue('single_payment.mandatory_extra_fields', {
      ...formik.values.single_payment.mandatory_extra_fields,
      [id]: checked,
    });
  };

  const changeBasketable = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formik.setFieldValue(
      'single_payment.is_basketable',
      event.target.value === 'true' ? true : false
    );
  };

  const changeLimitSelection = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.value === 'limitless') {
      setDisabledFields({ ...disabledFields, stock_limit: true, limit_date: true });
    } else if (event.target.value === 'stockLimit') {
      setDisabledFields({ ...disabledFields, stock_limit: false, limit_date: true });
    } else {
      setDisabledFields({ ...disabledFields, stock_limit: true, limit_date: false });
    }
    formik.setFieldValue('single_payment.stock_limit', null);
    formik.setFieldValue('single_payment.limit_date', '');
  };

  const changeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formik.handleChange(event);
    formik.setFieldValue('product.name', event.target.value);
  };

  const changeDefaultField = (checked: boolean, id: string) => {
    if (checked) {
      formik.setFieldValue('single_payment.default_extra_fields', {
        ...formik.values.single_payment.default_extra_fields,
        [id]: true,
      });
    } else {
      formik.setFieldValue(
        'single_payment.default_extra_fields',
        omit(formik.values.single_payment.default_extra_fields, id)
      );
    }
  };

  const changeFieldKind = (kind: string, id: string) => {
    formik.setFieldValue('single_payment.extra_fields_kind', {
      ...formik.values.single_payment.extra_fields_kind,
      [id]: kind,
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, id: string) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputValue.trim()) {
        const currentTags = formik.values.single_payment.extra_fields_options?.[id] || '';
        const newTags = currentTags ? `${currentTags},${inputValue.trim()}` : inputValue.trim();
        formik.setFieldValue(`single_payment.extra_fields_options.${id}`, newTags);
        setInputValue('');
      }
    }
  };

  const handleDelete = (tagToDelete: string, id: string) => {
    const currentTags = formik.values.single_payment.extra_fields_options?.[id] || '';
    const updatedTags = currentTags
      .split(',')
      .filter((tag) => tag !== tagToDelete)
      .join(',');

    formik.setFieldValue(`single_payment.extra_fields_options.${id}`, updatedTags);
  };

  const openOptionsDrawer = (id: string) => {
    setOptionsDrawerOpen(true);
    setSelectedField(id);
  };

  const closeOptionsDrawer = () => {
    setOptionsDrawerOpen(false);
    setSelectedField('');
  };

  const changeAskShipping = (checked: boolean) => {
    formik.setFieldValue('single_payment.ask_shipping_address', checked);
  };

  const openDispatchConfig = () => {
    window.open(`${FRONT_URL}/seller/company/dispatch_setup`);
  };

  const saveShippingConfig = (regions: string[], communes: { [key: string]: string[] }) => {
    formik.setFieldValue('single_payment.shipping_config', {
      regions: regions,
      communes_by_region: communes,
    });
    setShippingModalOpen(false);
  };

  const cancelShippingInfo = () => {
    formik.setFieldValue(
      'single_payment.shipping_config',
      props.initialData?.shipping_config || null
    );
    setShippingModalOpen(false);
  };

  const [urlImage, setUrlImage] = useState<string>(formik.values.single_payment.image || '');

  const onUploadImage = (event: any) => {
    setDialogOpen(!dialogOpen);
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  const onCropComplete = useCallback(async (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const acceptImage = async () => {
    if (croppedArea) {
      const canvas = await generateImage(image, croppedArea);
      canvas
        ? canvas.toBlob(
            (blob: Blob | null) => {
              if (blob) {
                const newImg = document.createElement('img'),
                  url = URL.createObjectURL(blob);

                newImg.onload = () => {
                  URL.revokeObjectURL(url);
                };
                setUrlImage(URL.createObjectURL(blob));
                newImg.src = url;
                formik.setFieldValue('single_payment.image_file', blob);
                enqueueSnackbar('Imagen procesada con éxito', { variant: 'success' });
              }
            },
            'image/png',
            0.66
          )
        : null;
      setDialogOpen(!dialogOpen);
    }
  };

  const limitComponentToRender = (): React.ReactElement => {
    if (!disabledFields.stock_limit && disabledFields.limit_date) {
      return (
        <TextField
          className={`${styles.amount} ${styles.input}`}
          id="single_payment.stock_limit"
          type="number"
          autoComplete="single_payment.stock_limit"
          placeholder="Máximo stock"
          fullWidth
          defaultValue={props.initialData?.stock_limit}
          disabled={disabledFields.stock_limit}
          variant="outlined"
          name="single_payment.stock_limit"
          value={formik.values.single_payment.stock_limit}
          onChange={formik.handleChange}
          error={
            getIn(formik.touched, 'single_payment.stock_limit') &&
            Boolean(getIn(formik.errors, 'single_payment.stock_limit'))
          }
          helperText={
            getIn(formik.touched, 'single_payment.stock_limit') &&
            getIn(formik.errors, 'single_payment.stock_limit')
          }
        />
      );
    } else if (disabledFields.stock_limit && !disabledFields.limit_date) {
      return (
        <TextField
          className={`${styles.amount} ${styles.input}`}
          id="single_payment.limit_date"
          type="date"
          autoComplete="single_payment.limit_date"
          fullWidth
          disabled={disabledFields.limit_date}
          variant="outlined"
          name="single_payment.limit_date"
          value={formik.values.single_payment.limit_date}
          defaultValue={props.initialData?.limit_date}
          onChange={formik.handleChange}
          error={
            getIn(formik.touched, 'single_payment.limit_date') &&
            Boolean(getIn(formik.errors, 'single_payment.limit_date'))
          }
          helperText={
            getIn(formik.touched, 'single_payment.limit_date') &&
            getIn(formik.errors, 'single_payment.limit_date')
          }
        />
      );
    }
    return (
      <TextField
        className={`${styles.amount} ${styles.input}`}
        fullWidth
        disabled
        variant="outlined"
      />
    );
  };

  useEffect(() => {
    if (props.initialData?.stock_limit) {
      setDisabledFields({ ...disabledFields, stock_limit: false, limit_date: true });
    } else if (props.initialData?.limit_date) {
      setDisabledFields({ ...disabledFields, stock_limit: true, limit_date: false });
    }
  }, [props.initialData]);

  useEffect(() => {
    sellerApi.defaultExtraField.list(company?.id || '').then((data) => {
      setDefaultExtraFields(data.data);
    });
  }, []);

  return (
    <form
      onKeyPress={(event: any) => event.which == 13 && event.preventDefault()}
      onSubmit={formik.handleSubmit}
    >
      <Paper className={styles.backgroundPaper}>
        <div className={styles.singlePaymentContainer}>
          <div className={styles.description}>
            <div className={styles.informationContainer}>
              <div className={styles.containerHeader}>
                <Typography variant="h6">Nombre*</Typography>
              </div>
              <TextField
                fullWidth
                required
                id="single_payment.name"
                type="text"
                autoComplete="single_payment.name"
                variant="outlined"
                value={formik.values.single_payment.name}
                onChange={(event) => changeName(event)}
                error={
                  getIn(formik.touched, 'single_payment.name') &&
                  Boolean(getIn(formik.errors, 'single_payment.name'))
                }
                helperText={
                  getIn(formik.touched, 'single_payment.name') &&
                  getIn(formik.errors, 'single_payment.name')
                }
              />
            </div>
            <div className={styles.informationContainer}>
              <div className={styles.containerHeader}>
                <Typography variant="h6">Descripción</Typography>
              </div>
              <TextField
                fullWidth
                id="single_payment.description"
                type="text"
                autoComplete="single_payment.description"
                variant="outlined"
                value={formik.values.single_payment.description}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className={styles.image}>
            <Grid item xs={6} className={styles.gridProductImage}>
              <Typography className={styles.photoTitle}>Imagen del Pago Único</Typography>
              <Paper className={styles.paperProductImage}>
                <Container
                  id="imageContainer"
                  className={styles.containerProductImage}
                  sx={{ backgroundImage: `url(${urlImage})` }}
                >
                  <Button className={styles.buttonProductImage} component="label">
                    <CameraAltOutlinedIcon sx={{ color: '#FFFFFF' }} />
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      id="image"
                      onChange={onUploadImage}
                    />
                  </Button>
                </Container>
              </Paper>
              <Typography className={styles.photoSubtitle}>
                Te aconsejamos agregarlo para que tus clientes lo visualicen.
              </Typography>
            </Grid>
          </div>
        </div>
      </Paper>

      {singlePayment?.single_payment_products.length === 1 &&
      singlePayment?.single_payment_products[0].product.kind !== 'common' ? null : (
        <Paper className={styles.backgroundPaper}>
          <div className={styles.informationContainer}>
            <div className={styles.containerHeader}>
              <Typography variant="h6">Selección de Productos/Servicios</Typography>
              <Typography variant="subtitle1">
                Estos productos/servicios serán parte de tu pago único
              </Typography>
            </div>
            {formik.values.products.length === 0 ? (
              <Typography>
                Si no agregas un producto, puedes setear el precio del pago único más abajo
              </Typography>
            ) : null}
            {formik.values.products?.map((product, index) => (
              <div key={index} className={styles.productContainer}>
                <Autocomplete
                  options={options.sort((a, b) => -b.groupLetter.localeCompare(a.groupLetter))}
                  groupBy={(option) => option.groupLetter}
                  filterOptions={(options, state) => {
                    const inputValue = state.inputValue.toLowerCase();
                    return options.filter(
                      (option) =>
                        option.title?.toLowerCase()?.includes(inputValue) ||
                        option.external_id?.toLowerCase()?.includes(inputValue)
                    );
                  }}
                  getOptionLabel={(option) => option.title}
                  onInputChange={(event, value) => handleQueryChange(event, value, product.id)}
                  onChange={(event, value) =>
                    setProduct(event, value, index, product.id, product.quantity)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...{
                        ...params,
                        inputProps: {
                          ...params.inputProps,
                          ...(product.company_id ? { value: product.name } : {}),
                        },
                      }}
                      value={product.name}
                      label="Buscar producto"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingProducts[product.id] ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  disabled={initialData.products.some((p) => p.id === product.id)}
                />
                <div className={styles.productQuantity}>
                  <Button
                    variant="outlined"
                    onClick={() => changeProductQty(index, 'minus')}
                    disabled={product.quantity === 1}
                  >
                    <Typography>-</Typography>
                  </Button>
                  <Typography>{product.quantity}</Typography>
                  <Button variant="outlined" onClick={() => changeProductQty(index, 'plus')}>
                    <Typography>+</Typography>
                  </Button>
                </div>
                <IconButton
                  size="medium"
                  onClick={() => deleteProduct(index)}
                  className={styles.icon}
                >
                  {<TrashIcon />}
                </IconButton>
              </div>
            ))}
            <div className={styles.addProductButton}>
              <Typography>
                <a onClick={addProduct}>Agregar producto</a>
              </Typography>
            </div>
          </div>
        </Paper>
      )}

      <Paper className={styles.backgroundPaper}>
        <div className={styles.informationContainer}>
          <div className={styles.containerHeader}>
            <Typography variant="h6">Reglas del pago único</Typography>
            <Typography variant="subtitle1">Configura y personaliza tu pago único</Typography>
          </div>
          <div className={styles.fieldContainer}>
            <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
              <Typography className={styles.fieldHeader}>Precio del pago único</Typography>
              <InfoButton text="price" context="singlePayment" />
            </Grid>
            <Typography className={styles.fieldHeader}></Typography>
            <div className={styles.priceContainer}>
              <TextField
                className={styles.currency}
                id="product.currency"
                type="text"
                select
                disabled={disabledFields.value}
                autoComplete="product.currency"
                variant="outlined"
                name="product.currency"
                value={formik.values.product.currency}
                onChange={formik.handleChange}
                error={
                  getIn(formik.touched, 'product.currency') &&
                  Boolean(getIn(formik.errors, 'product.currency'))
                }
                helperText={
                  (getIn(formik.touched, 'product.currency') &&
                    getIn(formik.errors, 'product.currency')) ||
                  'Moneda'
                }
              >
                {CURRENCIES.map((curr: string) => (
                  <MenuItem key={curr} value={curr}>
                    {curr}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={styles.amount}
                id="product.value"
                type="text"
                autoComplete="product.value"
                variant="outlined"
                name="product.value"
                disabled={disabledFields.value}
                required={!disabledFields.value}
                value={
                  disabledFields.value
                    ? undefined
                    : formik.values.product.currency === 'UF'
                    ? formatDecimalNumber(formik.values.product.value)
                    : formatNumber(formik.values.product.value)
                }
                onChange={(event) => {
                  const amount =
                    formik.values.product.currency === 'UF'
                      ? decimalNumberFormatter(event.target.value)
                      : plainNumberFormatter(event.target.value);
                  formik.setFieldValue('product.value', amount);
                }}
                placeholder={
                  disabledFields.value
                    ? formatNumber(
                        formik.values.products
                          .reduce(
                            (prev: number, curr) => prev + (curr.price || 0) * curr.quantity,
                            0
                          )
                          .toString() || '0'
                      )
                    : undefined
                }
                error={
                  getIn(formik.touched, 'product.value') &&
                  Boolean(getIn(formik.errors, 'product.value'))
                }
                helperText={
                  (getIn(formik.touched, 'product.value') &&
                    getIn(formik.errors, 'product.value')) ||
                  'Monto'
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formik.values.product.currency === 'CLP' ? '$' : 'UF'}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
              <Typography className={styles.fieldHeader}>Limitar link de pago</Typography>
              <InfoButton text="limit" context="singlePayment" />
            </Grid>
            <div className={styles.multiInputContainer}>
              <TextField
                fullWidth
                select
                variant="outlined"
                defaultValue={
                  initialData.single_payment.stock_limit
                    ? 'stockLimit'
                    : initialData.single_payment.limit_date
                    ? 'dateLimit'
                    : 'limitless'
                }
                onChange={changeLimitSelection}
                className={styles.option}
              >
                <MenuItem value={'limitless'}>Sin limitar</MenuItem>
                <MenuItem value={'stockLimit'}>Por stock</MenuItem>
                <MenuItem value={'dateLimit'}>Por fecha</MenuItem>
              </TextField>
              {limitComponentToRender()}
            </div>
          </div>

          <div className={styles.recurrenceContainer}>
            <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
              <Typography className={styles.fieldHeader}>
                Permitir una canasta en el link de pago
              </Typography>
              <InfoButton text="basket" context="singlePayment" />
            </Grid>
            <div className={styles.recurrenceInput}>
              <TextField
                className={styles.amount}
                id="single_payment.is_basketable"
                type="text"
                autoComplete="single_payment.is_basketable"
                fullWidth
                select
                variant="outlined"
                name="single_payment.is_basketable"
                value={formik.values.single_payment.is_basketable}
                onChange={changeBasketable}
                error={
                  getIn(formik.touched, 'single_payment.is_basketable') &&
                  Boolean(getIn(formik.errors, 'single_payment.is_basketable'))
                }
                helperText={
                  getIn(formik.touched, 'single_payment.is_basketable') &&
                  getIn(formik.errors, 'single_payment.is_basketable')
                }
              >
                <MenuItem value={'false'}>No</MenuItem>
                <MenuItem value={'true'}>Sí</MenuItem>
              </TextField>
            </div>
          </div>
        </div>
      </Paper>

      <Paper className={styles.backgroundPaper}>
        <div className={styles.informationContainer}>
          <div className={styles.containerHeader}>
            <Typography variant="h6">Datos solicitados al cliente</Typography>
            <Typography variant="subtitle1">{DESCRIPTIONS['additionalInfo']}</Typography>
          </div>
          <div className={styles.extraFieldsContainer}>
            <div className={styles.defaultExtraField}>
              <Typography variant="subtitle1">Nombre completo</Typography>
              <Typography variant="body1">Campo obligatorio. No se puede modificar.</Typography>
            </div>
            <div className={styles.defaultExtraField}>
              <Typography variant="subtitle1">Correo</Typography>
              <Typography variant="body1">Campo obligatorio. No se puede modificar.</Typography>
            </div>
            <Divider sx={{ marginBottom: '20px' }} />
            <div className={styles.defaultEfsContainer}>
              <Typography variant="h6">Campos extra predefinidos</Typography>
              <Typography variant="body1">
                Estos campos se pedirán siempre en caso de que los selecciones.
              </Typography>
              <div className={styles.defaultFieldRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="ask_shipping_address"
                      onChange={(e, checked) => changeAskShipping(checked)}
                      checked={formik.values.single_payment.ask_shipping_address}
                    />
                  }
                  label="Dirección de despacho"
                  labelPlacement="end"
                />
                <InfoButton text="ask_shipping_address" context="singlePayment" />
                {formik.values.single_payment.ask_shipping_address && (
                  <div className={styles.shippinExplain}>
                    <Typography>
                      Se ofrecerán por defecto estas{' '}
                      <a className={styles.addExtraFieldButton} onClick={openDispatchConfig}>
                        opciones de despacho
                      </a>
                      . O puedes definir opciones personalizadas para esta suscripción{' '}
                      <a
                        className={styles.addExtraFieldButton}
                        onClick={() => setShippingModalOpen(true)}
                      >
                        aquí
                      </a>
                    </Typography>
                  </div>
                )}
              </div>
              {defaultExtraFields?.map((def: DefaultExtraField) => (
                <div key={def.id} className={styles.defaultFieldRow}>
                  <FormControlLabel
                    value={def.kind}
                    control={<Checkbox />}
                    label={def.question}
                    labelPlacement="end"
                    onChange={(e, checked) => changeDefaultField(checked, def.id)}
                    checked={['string', 'boolean'].includes(
                      typeof formik.values.single_payment.default_extra_fields?.[def.id]
                    )}
                  />
                  <InfoButton text={def.kind} context="singlePayment" />
                </div>
              ))}
              <Divider sx={{ marginBottom: '20px', marginTop: '10px' }} />
            </div>
            {Object.keys(formik.values.single_payment.extra_fields || {}).map((id) => {
              return (
                <div key={id} className={styles.extraField}>
                  <TextField
                    label={'Campo adicional'}
                    value={formik.values.single_payment.extra_fields?.[id]}
                    placeholder={
                      formik.values.single_payment.extra_fields?.[id] || 'Campo adicional'
                    }
                    variant="outlined"
                    onChange={(event: any) =>
                      formik.setFieldValue('single_payment.extra_fields', {
                        ...formik.values.single_payment.extra_fields,
                        [id]: event.target.value.toString(),
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className={styles.adornment}>
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            onClick={() => eraseExtraField(id)}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label="Tipo"
                    defaultValue="string"
                    value={formik.values.single_payment.extra_fields_kind?.[id]}
                    onChange={(e) => changeFieldKind(e.target.value, id)}
                    className={styles.selector}
                  >
                    <MenuItem value={'string'}>Texto libre</MenuItem>
                    <MenuItem value={'selector'}>Selector</MenuItem>
                  </TextField>
                  {formik.values.single_payment.extra_fields_kind?.[id] === 'selector' && (
                    <div className={styles.addExtraFieldButton}>
                      <Typography
                        onClick={() => {
                          openOptionsDrawer(id);
                        }}
                      >
                        <a>Agregar opciones</a>
                      </Typography>
                    </div>
                  )}
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label={
                      formik.values.single_payment.mandatory_extra_fields?.[id]
                        ? 'Campo obligatorio'
                        : 'Campo no obligatorio'
                    }
                    onChange={(e, checked) => changeMandatoryField(checked, id)}
                    checked={formik.values.single_payment.mandatory_extra_fields?.[id]}
                  />
                </div>
              );
            })}
            <div className={styles.addExtraFieldButton}>
              <Typography onClick={addExtraField}>
                <a>Agregar otro campo</a>
              </Typography>
            </div>
          </div>
        </div>
      </Paper>
      <div className={styles.submitForm}>
        <Button
          disableElevation
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          className="loader"
        >
          {loading && <CircularProgress size={20} />}
          Guardar
        </Button>
      </div>
      <Dialog open={dialogOpen} keepMounted onClose={() => setDialogOpen(!dialogOpen)}>
        <Grid container>
          <Grid item xs={12}>
            <DialogTitle>Ajusta la imagen por favor</DialogTitle>
          </Grid>
          <Grid item xs={12} className={styles.dialogContainer}>
            <DialogContent>
              <div style={{ position: 'relative', width: '100%', height: 250 }}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  objectFit="contain"
                />
              </div>
              <div>
                <Slider
                  value={zoom}
                  min={1}
                  max={2}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: 'slider' }}
                />
              </div>
            </DialogContent>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => setDialogOpen(!dialogOpen)}>Cancelar</Button>
              <Button onClick={acceptImage}>Aceptar</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      <Drawer anchor="right" open={optionsDrawerOpen} onClose={() => closeOptionsDrawer()}>
        <div className={styles.drawerContainer}>
          <div className={styles.drawerField}>
            <Typography variant="h6" sx={{ margin: '0 10px 10px' }}>
              Opciones a ofrecer en el selector:
            </Typography>
            <Box width="100%">
              <TextField
                label="Agregar opción"
                variant="outlined"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, selectedField)}
                className={styles.inputField}
              />
              <Box sx={{ mt: 2 }}>
                {formik.values.single_payment.extra_fields_options?.[selectedField]
                  ?.split(',')
                  .map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      onDelete={() => handleDelete(tag, selectedField)}
                      sx={{ marginRight: 1, marginBottom: 1 }}
                    />
                  ))}
              </Box>
            </Box>
            <div className={styles.actions} style={{ marginTop: '50px', display: 'flex' }}>
              <Button
                variant="contained"
                onClick={() => {
                  closeOptionsDrawer();
                }}
              >
                Continuar
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      <PopUp
        state={{ open: shippingModalOpen, setOpen: setShippingModalOpen }}
        content={
          <ShippingOptions
            regions={formik.values.single_payment.shipping_config?.regions}
            communes_by_region={formik.values.single_payment.shipping_config?.communes_by_region}
            setConfiguration={saveShippingConfig}
            cancelUpdateShipping={cancelShippingInfo}
          />
        }
      />
    </form>
  );
};

export default EditSinglePayment;

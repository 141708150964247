// React, packages and api
import React, { useEffect, useRef, useState } from 'react';
import { sellerApi } from '../../../common/api';
import { useHistory, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import copyToClipboard from 'copy-text-to-clipboard';
import QRCode from 'qrcode';
import { jsPDF } from 'jspdf';

// Slice and utils
import { useSelector, useDispatch } from '../../../app/hooks';
import { SellerState, setBuyer } from '../sellerSlice';
import { formatCurrency, parseDatetime, setDocumentTitle } from '../../../common/utils';
import { FRONT_URL } from '../../../common/api/client';

// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import {
  InputAdornment,
  CircularProgress,
  Modal,
  Container,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material';

// Styles and assets
import styles from './Show.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import { Edit as EditIcon, Trash as TrashIcon, Copy as CopyIcon, X as XIcon } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faQrcode,
  faEye,
  faPaperPlane,
  faMoneyCheck,
  faTrashCan,
  faBagShopping,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

// Components
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';
import InfoBox from '../../../common/components/InfoBox';
import Stepper from '../../../common/components/Stepper';
import { Search as SearchIcon } from 'react-feather';
import Avatar from '../../../common/components/Avatar';

// Types
import { SinglePaymentBuyer, Statement, Email, Buyer, ApiList } from '../../../app/type';
import { Header } from '../../../common/components/ResourceListTypes';
import TableList from '../../../common/components/TableList';

let queryTimeout: ReturnType<typeof setTimeout>;

const SinglePaymentShow = (): React.ReactElement => {
  const { singlePayment, company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  setDocumentTitle(singlePayment?.name || 'Pagos Únicos');
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { enqueueSnackbar } = useSnackbar();
  const singlePaymentURL = `${FRONT_URL}/single_payments/${singlePayment?.id}`;
  const [QRopen, setQROpen] = useState<boolean>(false);
  const [QR, setQR] = useState<string>('');
  const doc = new jsPDF();
  const reloadRef = useRef<{ reloadResource: () => void }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [spBuyerState, setSpBuyerState] = useState<SinglePaymentBuyer>();
  const [openMarkPayment, setOpenMarkPayment] = useState<boolean>(false);
  const [openDelete, setDeleteOpen] = useState(false);
  const [openDeleteSinglePayment, setDeleteSinglePaymentOpen] = useState(false);
  const [openLimitedProduct, setOpenLimitedProduct] = useState<boolean>(false);
  const [acceptedSub, setAcceptedSub] = useState<SinglePaymentBuyer>();
  const [email, setEmail] = useState<Email>();
  const [openSendEmail, setOpenSendEmail] = useState<boolean>(false);
  const [selectedStatement, setSelectedStatement] = useState<Statement>();
  const [openRefundPopup, setOpenRefundPopup] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectBuyerOpen, setSelectBuyerOpen] = useState<boolean>(false);
  const [buyers, setBuyers] = useState<Buyer[]>([]);
  const [buyersQuery, setBuyersQuery] = useState<string>();
  const [query, setQuery] = useState<string>();
  const [buyerMenuEl, setBuyerMenuEl] = useState(null);
  const openBuyerMenu = (event: any) => setBuyerMenuEl(event?.currentTarget);
  const closeBuyerMenu = () => setBuyerMenuEl(null);

  const loadBuyers = () => {
    if (company) {
      setLoading(true);
      sellerApi.buyers
        .list(company.id, buyersQuery)
        .then((data: ApiList<Buyer>) => {
          setBuyers([...data.data]);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    loadBuyers();
  }, [company, buyersQuery]);

  const onBuyerSubscribe = (buyerId: string) => {
    setLoading(true);
    sellerApi.singlePayments
      .singlePaymentBuyer(singlePayment?.id || '', buyerId)
      .then(() => {
        loadBuyers();
        reloadRef?.current?.reloadResource?.();
      })
      .catch(() => {
        enqueueSnackbar('Ocurrió un error al agregar al cliente', { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (queryTimeout) clearTimeout(queryTimeout);
    setQuery(event.target.value);

    queryTimeout = setTimeout(() => {
      setBuyersQuery(event.target.value);
    }, 500);
  };

  const startAd = (): React.ReactElement => {
    return (
      <InputAdornment position="start">
        {loading ? <CircularProgress size={18} /> : <SearchIcon />}
      </InputAdornment>
    );
  };

  const onSubscribeBuyer = () => {
    closeBuyerMenu();
    setSelectBuyerOpen(true);
  };

  const onCreateBuyer = () => {
    dispatch(setBuyer(undefined));
    history.push(`/seller/single_payments/${singlePayment?.id}/new_buyer`);
  };

  const singlePaymentName =
    singlePayment?.name ||
    singlePayment?.products.reduce(
      (prev, curr, index) => prev + `${index === 0 ? '' : ', '}${curr.name}`,
      ''
    );

  const onLinkCopy = () => {
    copyToClipboard(singlePaymentURL);
    enqueueSnackbar('Enlace copiado', { variant: 'info' });
  };

  useEffect(() => {
    QRCode.toDataURL(singlePaymentURL).then((url) => {
      setQR(url);
    });
  }, [singlePaymentURL]);

  const refundStatementAction = (statement: Statement) => {
    setOpenRefundPopup(true);
    setSelectedStatement(statement);
  };

  const refundStatement = (status: string) => {
    setOpenRefundPopup(false);
    enqueueSnackbar('Estamos procesando tu solicitud', {
      variant: 'success',
    });
    sellerApi.statements
      .refund(selectedStatement?.id || '', selectedStatement?.group_id, status)
      .then((response) => {
        enqueueSnackbar(
          response.message === 'kushki'
            ? 'La devolución está siendo procesada por el proveedor'
            : status === 'refund'
            ? 'Pago reembolsado exitosamente'
            : 'Solicitud procesada con éxito',
          {
            variant: 'success',
          }
        );
      })
      .catch((reason) => {
        let text = '';
        if (reason.response.data.errors[0].title === 'Forbidden')
          text = 'Necesitas ser administrador para realizar esta acción';
        if (reason.response.data.message === 'fintoc not allowed')
          text = 'Fintoc no está habilitado para realizar devoluciones';
        if (reason.response.data.message === 'expired_time')
          text = 'Han pasado más de 14 días desde que se hizo el pago';
        if (reason.response.data.message === 'exceed_amount')
          text = 'No tienes saldo suficiente para devolver el monto';
        if (reason.response.data.message === 'not_credit_card')
          text = 'Solo se pueden hacer devoluciones de tarjetas de crédito';
        enqueueSnackbar(text || 'No pudimos devolver el pago, inténtalo más tarde', {
          variant: 'error',
        });
      })
      .finally(() => {
        setSelectedStatement(undefined);
      });
  };

  const openLimitDialog = (context: string, singlePaymentBuyer?: SinglePaymentBuyer) => {
    if (singlePayment?.over_stock || singlePayment?.over_date) {
      setOpenLimitedProduct(true);
      if (singlePaymentBuyer) setAcceptedSub(singlePaymentBuyer);
    } else {
      if (context == 'chargeSinglePayment' && singlePaymentBuyer)
        chargeSinglePayment(singlePaymentBuyer);
    }
  };

  const acceptLimitDialog = () => {
    setOpenLimitedProduct(false);
    if (acceptedSub) chargeSinglePayment(acceptedSub);
  };

  const chargeSinglePayment = (singlePaymentBuyer: SinglePaymentBuyer) => {
    sellerApi.singlePayments
      .subscribe(singlePaymentBuyer.single_payment_id, singlePaymentBuyer.buyer.id)
      .then(() => {
        sendChargeEmail(singlePaymentBuyer);
        enqueueSnackbar('Cliente actualizado', { variant: 'info' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch(() => {
        enqueueSnackbar('Ocurrió un error al actualizar el cliente', { variant: 'error' });
      });
  };
  const checkChargeEmail = (singlePaymentBuyer?: SinglePaymentBuyer) => {
    if (!singlePaymentBuyer?.id) return;
    sellerApi.singlePaymentBuyers
      .checkChargeEmail(singlePaymentBuyer.id)
      .then((data: Email) => {
        setEmail(data);
      })
      .catch(() => {
        setEmail(undefined);
      })
      .finally(() => {
        setOpenSendEmail(true);
        reloadRef?.current?.reloadResource?.();
      });
  };

  const deleteSinglePayment = () => {
    sellerApi.singlePayments
      .destroySinglePayment(singlePayment?.id)
      .then(() => {
        enqueueSnackbar('Se eliminó el pago único', { variant: 'info' });
        history.push('/seller/single_payments');
      })
      .catch((error) => {
        if (error.response.status === 403) {
          enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
            variant: 'error',
          });
        } else if (error.response.status === 400) {
          enqueueSnackbar('El cliente no puede ser eliminado por tener pagos completados', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('No se pudo eliminar el cliente del pago único', { variant: 'error' });
        }
      })
      .finally(() => reloadRef?.current?.reloadResource?.());
  };

  const deleteSinglePaymentBuyer = (singlePaymentBuyer?: SinglePaymentBuyer) => {
    if (!singlePaymentBuyer?.id) return;
    sellerApi.singlePaymentBuyers
      .destroySinglePaymentBuyer(singlePaymentBuyer.id)
      .then(() => enqueueSnackbar('Se eliminó el cliente del pago único', { variant: 'info' }))
      .catch((error) => {
        if (error.response.status === 403) {
          enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
            variant: 'error',
          });
        } else if (error.response.status === 400) {
          enqueueSnackbar('El cliente no puede ser eliminado por tener pagos completados', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('No se pudo eliminar el cliente del pago único', { variant: 'error' });
        }
      })
      .finally(() => {
        reloadRef?.current?.reloadResource?.();
        setDeleteOpen(false);
      });
  };

  const markStatementAsPaid = (statement?: Statement) => {
    if (!statement?.id) return;
    sellerApi.statements
      .markAsPaid(statement.id)
      .then(() => enqueueSnackbar('Cuota actualizada', { variant: 'info' }))
      .catch((error) => {
        if (error.response.status === 403) {
          enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Cuota no pudo ser actualizada', { variant: 'error' });
        }
      })
      .finally(() => reloadRef?.current?.reloadResource?.());
  };

  const sendChargeEmail = (singlePaymentBuyer?: SinglePaymentBuyer) => {
    if (!singlePaymentBuyer?.id) return;
    sellerApi.singlePaymentBuyers
      .sendChargeEmail(singlePaymentBuyer.id)
      .then(() => {
        enqueueSnackbar('Se enviará correo de cobro', { variant: 'info' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Correo no pudo ser enviado', { variant: 'error' });
        }
      });
  };

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="TOTAL RECAUDADO DEL MES"
      data={formatCurrency(singlePayment?.total_month_gathered)}
      kind="info2"
      style={styles.firstInfoContainer}
      icon={<FontAwesomeIcon icon={faBagShopping} className="icon" />}
      width={width}
    />,
    <InfoBox
      key={2}
      title="NÚMERO DE CLIENTES"
      data={singlePayment?.total_clients || 0}
      kind="info2"
      style={styles.thirdInfoContainer}
      icon={<FontAwesomeIcon icon={faUsers} className="icon" />}
      width={width}
    />,
    <InfoBox
      key={3}
      title="Agregar Cliente"
      function={openBuyerMenu}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/girl%20and%20boy%20working%20together%20in%20front%20of%20laptop.svg"
      style={styles.linkContainer}
      width={width}
    />,
  ];

  return (
    <>
      <div className={styles.start}>
        <Typography variant={isMobile ? 'h5' : 'h4'}>
          <CopyIcon />
          <span className={styles.startTitle}>Pagos Únicos</span>
        </Typography>
        <Typography variant="body1">
          Los <b>pagos únicos</b> se cobran solo una vez.
        </Typography>
      </div>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            {/* HEADER */}
            <Grid container>
              <Grid item xs={12}>
                <div className={cStyles.paperHeader}>
                  <Avatar
                    className={cStyles.paperHeaderAvatar}
                    text={singlePayment?.name}
                    img={singlePayment?.image}
                    context="product"
                  />
                  <div className={cStyles.paperHeaderContent}>
                    <Typography variant={isMobile ? 'h6' : 'h5'}>
                      <b>{singlePaymentName}</b>
                    </Typography>
                    {!isMobile && (
                      <Typography variant="subtitle1">{singlePayment?.description}</Typography>
                    )}
                  </div>
                  <div className={cStyles.paperHeaderActions}>
                    <div className={`${cStyles.baseAction} ${cStyles.editAction}`}>
                      <IconButton
                        size="medium"
                        component={Link}
                        to={`/seller/single_payments/${singlePayment?.id}/edit`}
                        className={`${cStyles.icon} ${cStyles.editIcon}`}
                      >
                        {<EditIcon />}
                      </IconButton>
                      <Typography variant="body2">Editar</Typography>
                    </div>
                    <div className={`${cStyles.baseAction} ${cStyles.deleteAction}`}>
                      <IconButton
                        size="medium"
                        onClick={() => setDeleteSinglePaymentOpen(true)}
                        className={`${cStyles.icon} ${cStyles.deleteIcon}`}
                      >
                        {<TrashIcon />}
                      </IconButton>
                      <Typography variant="body2">Eliminar</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/* INFO */}
            <Grid container className={cStyles.infoContainer}>
              <Grid item xs={6} md={4}>
                <Typography variant="caption">Valor:</Typography>
                <Typography variant="h6">
                  <b>{formatCurrency(singlePayment?.render_amount, singlePayment?.currency)}</b>
                </Typography>
              </Grid>
              {singlePayment?.stock_limit ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">Límite de stock:</Typography>
                  <Typography variant="h6">
                    <b>{singlePayment?.stock_limit}</b>
                  </Typography>
                </Grid>
              ) : null}
              {singlePayment?.limit_date ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">Fecha límite:</Typography>
                  <Typography variant="h6">
                    <b>{singlePayment?.limit_date}</b>
                  </Typography>
                </Grid>
              ) : null}
              {singlePayment?.is_basketable ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">¿Tiene canasta?</Typography>
                  <Typography variant="h6">
                    <b>{singlePayment.is_basketable ? 'Sí' : 'No'}</b>
                  </Typography>
                </Grid>
              ) : null}
              {singlePayment?.ask_shipping_address ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">¿Pide dirección de despacho?</Typography>
                  <Typography variant="h6">
                    <b>Sí</b>
                  </Typography>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Typography variant="caption">Datos a pedir al cliente:</Typography>
              </Grid>
              <Grid container spacing={1} mb={1} mt={0.5}>
                <Grid item>
                  <Chip label="Nombre" />
                </Grid>
                <Grid item>
                  <Chip label="Email" />
                </Grid>
                {singlePayment?.extra_fields
                  ? Object.keys(singlePayment?.extra_fields).map((key: string) => {
                      return singlePayment?.extra_fields ? (
                        <Grid item key={key}>
                          <Chip label={singlePayment?.extra_fields?.[key]} />
                        </Grid>
                      ) : null;
                    })
                  : null}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">Enlace del pago único:</Typography>
                <div className={cStyles.linkContainer}>
                  <IconButton
                    size="medium"
                    // disabled={loading}
                    className={cStyles.copyIcon}
                    onClick={onLinkCopy}
                  >
                    {loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faCopy} />}
                  </IconButton>
                  <IconButton
                    size="medium"
                    // disabled={loading}
                    className={cStyles.QRIcon}
                    onClick={() => setQROpen(true)}
                  >
                    {loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faQrcode} />}
                  </IconButton>
                  <Typography variant="h6">
                    <Link to={`/single_payments/${singlePayment?.id}`}>{singlePaymentURL}</Link>
                  </Typography>
                </div>
              </Grid>

              {singlePayment?.single_payment_products.length === 1 &&
              singlePayment?.single_payment_products[0].product.kind !== 'common' ? null : (
                <Grid container className={styles.productsGrid}>
                  <Typography className={styles.text}>Resumen Productos</Typography>
                  <Grid item container xs={12} className={styles.header}>
                    <Grid item xs={4}>
                      <Typography>Producto</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Cantidad</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Total</Typography>
                    </Grid>
                  </Grid>
                  {singlePayment?.single_payment_products.map((singlePaymentProduct) => (
                    <Grid
                      item
                      container
                      xs={12}
                      key={singlePaymentProduct.id}
                      className={styles.row}
                    >
                      <Grid item xs={4}>
                        <Typography>{singlePaymentProduct.product.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{singlePaymentProduct.quantity}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {formatCurrency(
                            singlePaymentProduct.value,
                            singlePaymentProduct.product.currency
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {isMobile ? (
        <Button className={styles.toButton} onClick={openBuyerMenu} variant="contained">
          Agregar Cliente
        </Button>
      ) : null}

      {isMobile ? (
        <Stepper assets={boxes(12).slice(0, -1)} />
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes().map((box) => box)}
        </Grid>
      )}

      <ResourceList
        title="Clientes"
        getResourceList={sellerApi.singlePayments.singlePaymentBuyers}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.singlePayments.generateSinglePaymentBuyersExcel(
            'single_payment',
            parentId,
            query,
            filterParams
          )
        }
        queryFields="Nombre, correo"
        resourceParent={singlePayment}
        innerRef={reloadRef}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'email', label: 'Correo' },
          { key: 'statement_status', label: 'Estado pago' },
          { key: 'payment_date', label: 'Fecha de pago' },
        ]}
        listMobileHeaders={[{ key: 'name' }, { key: 'statement_status' }, { key: 'payment_date' }]}
        listColumns={{
          name: (res: SinglePaymentBuyer) => res.buyer?.name,
          email: (res: SinglePaymentBuyer) => res.buyer?.email,
          statement_status: (res: SinglePaymentBuyer) => (
            <StatusLabel type="statement" status={res.current_statement?.status || 'pending'} />
          ),
          payment_date: (res: SinglePaymentBuyer) =>
            res.status !== 'pending' ? res.current_statement?.payment_date : '-',
          amount: (res: SinglePaymentBuyer) => formatCurrency(res.current_statement?.amount),
          ...Object.keys(singlePayment?.extra_fields || {})
            .map((key) => {
              return {
                [key]: (res: SinglePaymentBuyer) => res.extra_fields?.[key] || '-',
              };
            })
            .reduce((prev, curr) => {
              return { ...prev, ...curr };
            }, {}),
        }}
        listActionsHeaders={(res?: SinglePaymentBuyer) => {
          if (res)
            return [
              { key: 'show', label: 'Ver cliente', icon: faEye },
              res.current_statement?.status == 'paid'
                ? {
                    key: 'charge',
                    label: 'Cobrar nuevamente',
                    icon: faPaperPlane,
                  }
                : null,
              ['pending', 'active', 'indebt'].includes(res.status)
                ? {
                    key: 'collect',
                    label: 'Cobrar cuota',
                    icon: faPaperPlane,
                  }
                : null,
              ['pending', 'active', 'indebt'].includes(res.status)
                ? {
                    key: 'manual_payment',
                    label: 'Marcar como pagado',
                    icon: faMoneyCheck,
                  }
                : null,
              ['pending', 'active', 'indebt'].includes(res.status)
                ? {
                    key: 'delete_single_payment_buyer',
                    label: 'Eliminar',
                    icon: faTrashCan,
                    color: 'error',
                  }
                : null,
            ];
          return [null];
        }}
        listActions={{
          show: (res: SinglePaymentBuyer) => {
            dispatch(setBuyer(res.buyer));
            history.push(`/seller/buyers/${res.buyer?.id}`);
          },
          charge: (res: SinglePaymentBuyer) => openLimitDialog('chargeSubscription', res),
          manual_payment: (res: SinglePaymentBuyer) => {
            setSpBuyerState(res);
            setOpenMarkPayment(true);
          },
          collect: (res: SinglePaymentBuyer) => {
            setSpBuyerState(res);
            checkChargeEmail(res);
          },
          delete_single_payment_buyer: (res: SinglePaymentBuyer) => {
            setDeleteOpen(true);
            setSpBuyerState(res);
          },
        }}
        chips={Object.keys(singlePayment?.extra_fields || {}).reduce(
          (prev: Header[], curr: string) => {
            if (!singlePayment?.extra_fields[curr]) return prev;
            const hash = { key: curr, label: singlePayment?.extra_fields[curr] };
            return [...prev, hash];
          },
          []
        )}
        getCollapseResources={sellerApi.singlePaymentBuyers.statements}
        listCollapseHeaders={[
          { key: 'payment_date', label: 'Fecha de Pago' },
          { key: 'status', label: 'Estado' },
          { key: 'amount', label: 'Monto' },
          { key: 'payment_method', label: 'Método de Pago' },
        ]}
        listCollapseColumns={{
          payment_date: (res: Statement) => res.payment_date || '-',
          status: (res: Statement) => <StatusLabel type="statement" status={res.status} />,
          amount: (res: Statement) => formatCurrency(res.amount),
          payment_method: (res: Statement) => (
            <span className={styles.noWrap}>{res.payment_type_translated || '-'}</span>
          ),
        }}
        listMobileCollapseHeaders={[
          { key: 'payment_date', label: 'Fecha de Pago' },
          { key: 'status', label: 'Estado' },
          { key: 'payment_method', label: 'Método de Pago' },
        ]}
        filtersKey="single_payments_show"
        listCollapseActionsHeaders={(res?: Statement) => {
          if (res) {
            return [
              ['Fintoc', 'Método externo'].includes(res.payment_type_translated || 'none') ||
              ['refunded', 'expired', 'pending', 'processing_refund', 'refunded_manually'].includes(
                res.status
              )
                ? null
                : {
                    key: 'refund',
                    label: 'Devolución',
                  },
            ];
          }
          return [null];
        }}
        listCollapseActions={{
          refund: (res: Statement) => refundStatementAction(res),
        }}
      />

      {/* DIALOGS */}
      <PopUp
        state={{ open: QRopen, setOpen: setQROpen }}
        title={
          <>
            <Typography variant="h5" align="center">
              {singlePayment?.name}
            </Typography>
            <Typography variant="subtitle1">{company?.name}</Typography>
          </>
        }
        content={<img src={QR}></img>}
        extraActions={[
          <Button
            key={1}
            size="large"
            variant="outlined"
            color="primary"
            onClick={() => setQROpen(false)}
          >
            <b>Cancelar</b>
          </Button>,
          <Button
            key={2}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              doc.setFontSize(25);
              doc.text(singlePayment?.name || '', 105, 25, { align: 'center' });
              doc.text(company?.name || '', 105, 45, { align: 'center' });
              doc.addImage(QR, 'JPEG', 5, 50, 200, 200);
              doc.save(`${singlePayment?.name}.pdf`);
            }}
          >
            <b>Descargar</b>
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openLimitedProduct, setOpen: setOpenLimitedProduct }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            {singlePayment?.over_stock ? 'No tienes suficiente stock.' : null}
            {singlePayment?.over_date ? 'La fecha límite ya ha pasado.' : null}
          </Typography>
        }
        extraActions={[
          <Button onClick={acceptLimitDialog} color="info" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setOpenLimitedProduct(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: openDeleteSinglePayment, setOpen: setDeleteSinglePaymentOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se eliminará el pago único. Todas las inscripciones a este pago único se eliminarán.
            Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => deleteSinglePayment()} color="error" variant="contained" key={1}>
            Si
          </Button>,
          <Button onClick={() => setDeleteSinglePaymentOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: openDelete, setOpen: setDeleteOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se eliminará el cliente de este pago único. Esta acción es irreversible. ¿Quieres
            continuar?
          </Typography>
        }
        extraActions={[
          <Button
            onClick={() => deleteSinglePaymentBuyer(spBuyerState)}
            color="error"
            variant="contained"
            key={1}
          >
            Si
          </Button>,
          <Button onClick={() => setDeleteOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openMarkPayment, setOpen: setOpenMarkPayment }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Al marcar la cuota como pagada se actualizará el estado del cliente.
            </Typography>
          </>
        }
        extraActions={[
          <Button
            onClick={() => {
              markStatementAsPaid(spBuyerState?.current_statement);
              setOpenMarkPayment(false);
            }}
            color="info"
            variant="contained"
            key={1}
          >
            Marcar
          </Button>,
          <Button onClick={() => setOpenMarkPayment(false)} variant="outlined" key={2}>
            Cancelar
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openSendEmail, setOpen: setOpenSendEmail }}
        content={
          <>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Se enviará un mail con el link de pago al cliente al correo:{' '}
              <b>{spBuyerState?.buyer.email}</b>. ¿Quieres continuar?
            </Typography>
            {email ? (
              <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
                Ya enviaste un correo cobrando este pago. El último correo que le enviaste fue el{' '}
                {parseDatetime(email.sent_at) || ''}. Recuerda que solo puedes enviar el correo de
                pago cada 30 minutos.
              </Typography>
            ) : null}
          </>
        }
        extraActions={[
          <Button onClick={() => setOpenSendEmail(false)} variant="outlined" key={2}>
            Cancelar
          </Button>,
          <Button
            onClick={() => {
              sendChargeEmail(spBuyerState);
              setOpenSendEmail(false);
            }}
            color="info"
            variant="contained"
            key={1}
          >
            Enviar
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openRefundPopup, setOpen: setOpenRefundPopup }}
        title={
          <Typography variant="h6" align="center">
            Devolución pago
          </Typography>
        }
        content={
          <>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Para devolver un pago tienes dos opciones, que la plataforma procese la devolución a
              través del medio de pago con el que se realizó el pago. O marcar el pago como
              &quot;Devuelto manualmente&quot; y que tú gestiones la devolución por fuera de la
              plataforma.
            </Typography>
          </>
        }
        extraActions={[
          <Button onClick={() => setOpenRefundPopup(false)} variant="outlined" key={1}>
            Cancelar
          </Button>,
          <Button
            color="info"
            variant="contained"
            onClick={() => refundStatement('refund')}
            key={2}
          >
            Devolver cuota
          </Button>,
          <Button
            onClick={() => {
              refundStatement('refund_manually');
            }}
            color="info"
            variant="contained"
            key={3}
          >
            Marcar como devuelta
          </Button>,
        ]}
      />

      <Modal open={selectBuyerOpen} onClose={() => setSelectBuyerOpen(false)}>
        <Container maxWidth="md" style={{ margin: 'auto' }}>
          <Paper className={cStyles.modal}>
            <div className={cStyles.modalHeader}>
              <Typography variant="h6">Selecciona clientes para agregar</Typography>
              <IconButton size="medium" color="primary" onClick={() => setSelectBuyerOpen(false)}>
                {loading ? <CircularProgress size={20} /> : <XIcon />}
              </IconButton>
            </div>
            <div style={{ textAlign: 'center', margin: 'auto' }}>
              <TextField
                hiddenLabel
                className={styles.queryBox}
                id="query"
                type="text"
                autoComplete="query"
                variant="outlined"
                size="small"
                value={query}
                onChange={handleQueryChange}
                InputProps={{
                  startAdornment: startAd(),
                  placeholder: 'Nombre, correo',
                }}
                fullWidth={isMobile}
              />
            </div>

            <TableList
              className={cStyles.modalTable}
              resources={buyers}
              headers={[
                { key: 'name', label: 'Nombre' },
                { key: 'email', label: 'Correo' },
              ]}
              columns={{
                name: (res: Buyer) => res.name,
                email: (res: Buyer) => res.email,
              }}
              listActions={{ add: (res: Buyer) => onBuyerSubscribe(res.id) }}
              listActionsHeaders={(res?: Buyer) => {
                if (res) return [{ key: 'add', label: 'Agregar' }];
                return [null];
              }}
            />
          </Paper>
        </Container>
      </Modal>
      <Menu
        keepMounted
        anchorEl={buyerMenuEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="add-buyer-menu"
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(buyerMenuEl)}
        onClose={closeBuyerMenu}
      >
        <MenuItem onClick={onSubscribeBuyer}>Cliente existente</MenuItem>
        <MenuItem onClick={onCreateBuyer}>Crear cliente</MenuItem>
      </Menu>
    </>
  );
};

export default SinglePaymentShow;
